// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.C_k0jAAu3AtwgIcFjTHE {
    margin: 0 0 calc(0.5 * var(--globals-baseUnit)) 0;
    display: inline-block;
}
`, "",{"version":3,"sources":["webpack://./../../packages/design_system_modules/forms/inputs/src/atoms/wrapper/FieldWrapper.module.css"],"names":[],"mappings":"AAAA;IACI,iDAAiD;IACjD,qBAAqB;AACzB","sourcesContent":[".labelStyle {\n    margin: 0 0 calc(0.5 * var(--globals-baseUnit)) 0;\n    display: inline-block;\n}\n"],"sourceRoot":""}]);
// Exports
export var labelStyle = `C_k0jAAu3AtwgIcFjTHE`;
export default ___CSS_LOADER_EXPORT___;
