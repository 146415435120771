import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const FiltersIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M2.23 1h-.615v2.461H1v.616h1.846v-.616H2.23V1ZM4.692 1h.615v3.692h.615v.616H4.077v-.616h.615V1ZM1.615 4.692h.615V9h-.615V4.692ZM8.385 5.308H7.77V9h.615V5.308ZM4.692 5.924h.615V9h-.615V5.924ZM8.385 1v3.077H9v.615H7.153v-.615h.615V1h.617Z" />
        </SvgIcon>
    );
};
