import * as pathToRegexp from "path-to-regexp";

import {prefixPath} from "@pg-mono/prefix-path";

import {createNoParamLink} from "./utils/create_no_param_link";
import {V1_API_PREFIX} from "./api_prefixes";

type TDataQuery<T> = {
    [P in keyof T]?: TDataQuery<T[P]> | boolean;
};

/**
 * API path
 */
export const apiPath = prefixPath(V1_API_PREFIX, {
    applications: prefixPath("applications/", {
        base: "",
        application: prefixPath("application/", {
            base: "",
            detail: ":uuid([\\w-]+)/"
        }),
        cancel: prefixPath("cancel/", {
            base: "",
            detail: prefixPath(":uuid([\\w-]+)/", {
                base: ""
            })
        }),
        create: prefixPath("create/", {
            base: ""
        }),
        sent: prefixPath("applicationsent/", {
            base: ""
        }),
        validate: prefixPath("validate/", {
            base: ""
        }),
        update: prefixPath("update/", {
            base: "",
            detail: prefixPath(":uuid([\\w-]+)/", {
                base: ""
            })
        })
    }),
    architects: prefixPath("architects/", {
        applications: prefixPath("applications/", {
            base: "",
            create: prefixPath("create/", {
                base: ""
            })
        })
    }),
    articles: prefixPath("articles/", {
        article: prefixPath("article/", {
            base: "",
            rate: "rate/",
            detail: prefixPath(":articleId(\\d+)/", {
                base: ""
            })
        }),
        author: prefixPath("author/", {
            base: "",
            detail: prefixPath(":authorId(\\d+)/", {
                ask: "ask/",
                base: ""
            })
        }),
        archive: prefixPath("archive/", {
            base: ""
        }),
        category: prefixPath("category/", {
            base: ""
        })
    }),
    buildings: prefixPath("buildings/", {
        building: prefixPath("building/", {
            base: "",
            detail: prefixPath(":buildingId(\\d+)/", {
                base: ""
            })
        })
    }),
    opendays: prefixPath("opendays/", {
        base: "",
        campaign: prefixPath("campaign/", {
            base: "",
            detail: ":campaignUuid([\\w-]+)/"
        })
    }),
    comments: prefixPath("comments/", {
        base: "",
        article: prefixPath("article/", {
            base: ""
        })
    }),
    contact: prefixPath("contact/", {
        base: "",
        land_purchase: prefixPath("land-purchase/", {
            base: ""
        }),
        our_offer: "our-offer/",
        our_contractor: "our-contractor/"
    }),
    clipPhones: prefixPath("clip-phones/", {
        property: "property/:propertyId(\\d+)/"
    }),
    cookies: prefixPath("cookies/", {
        base: ""
    }),
    favourites: prefixPath("favourites/", {
        base: "",
        favourite: prefixPath("favourite", {
            base: ""
        })
    }),
    floors: prefixPath("floors/", {
        base: "",
        floor: prefixPath("floor/", {
            base: "",
            detail: prefixPath(":floorId(\\d+)/", {
                base: ""
            })
        })
    }),
    hidden: prefixPath("hidden/", {
        base: "",
        hidden: prefixPath("hidden/", {
            base: ""
        })
    }),
    newsletter: prefixPath("newsletter/", {
        base: "",
        subscribe: prefixPath("subscribe/", {
            base: "",
            guide_abc: "guide-abc/",
            regions: "regions/"
        }),
        unsubscribe: prefixPath("unsubscribe/", {
            mailing: "mailing/:emailHash/:verificationHash/",
            sms: "sms/:subscriberUuid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/"
        })
    }),
    housing_reports: prefixPath("housingreports/", {
        create: "create/"
    }),
    notus: prefixPath("notus/", {
        base: "",
        maintenance_cost: "maintenance-costs/",
        traders: "traders/"
    }),
    offers: prefixPath("offers/", {
        offer: prefixPath("offer/", {
            base: "",
            detail: prefixPath(":offerId(\\d+)/", {
                base: ""
            })
        })
    }),
    one_signal: prefixPath("onesignal/", {
        players: "players/"
    }),
    our_offer: "contact/our-offer/",
    partners: prefixPath("partners/", {
        base: ""
    }),
    places: prefixPath("places/", {
        user: "userplace/"
    }),
    planner: prefixPath("planner/", {
        base: "",
        buildings: prefixPath("buildings/", {
            detail: prefixPath(":buildingUuid/", {
                base: ""
            })
        }),
        estates: prefixPath("estates/", {
            detail: prefixPath(":propertyUuid/", {
                base: ""
            })
        })
    }),
    promotions: prefixPath("promotions/", {
        promotion: prefixPath("promotion/", {
            base: ""
        })
    }),
    properties: prefixPath("properties/", {
        property: prefixPath("property/", {
            base: "",
            detail: prefixPath(":propertyId(\\d+)/", {
                base: ""
            })
        }),
        stats: prefixPath("stats/", {
            base: ""
        })
    }),
    ratings: prefixPath("ratings/", {
        city: "cities/",
        monthly: "monthly/",
        old: "offerrating/",
        quarters: "quarters/",
        quarterly: "quarterly/",
        partners: "partners/"
    }),
    redirect: prefixPath("redirects/", {
        redirect: "redirect/"
    }),
    search: prefixPath("search/", {
        search: "search/",
        for_investor: "for-investor/"
    }),
    sessions: prefixPath("sessions/", {
        is_authenticated: "user_is_authenticated/",
        user_info: "user_info/"
    }),
    stats: prefixPath("stats/", {
        BASE: "",
        leading_cities: "leading-cities/",
        investment_offers: "investment-offers/"
    }),
    users: prefixPath("users/", {
        login: "login/",
        logout: "logout/",
        register: "register/",
        register2: "register2/",
        profile: prefixPath("me/", {
            base: "",
            reports: prefixPath("reports/", {
                base: "",
                detail: ":reportId([\\w-]+)/"
            })
        }),
        preferences: "preferences/",
        exist: "exist/",
        share_account: "share/",
        password: prefixPath("password/", {
            change: "change/",
            reset: prefixPath("reset/", {
                request: "",
                verify: "verify/",
                confirm: "confirm/"
            })
        }),
        notifications: "notifications/",
        reporting_content: "reporting-content/"
    }),
    variables: "variables/",
    vendors: prefixPath("vendors/", {
        base: "",
        vendor: prefixPath("vendor/", {
            base: "",
            detail: prefixPath(":vendorId(\\d+)/", {
                base: "",
                group: prefixPath("group/", {
                    base: ""
                })
            })
        })
    }),
    marketplace: prefixPath("marketplace/", {
        applications: prefixPath("applications/", {
            create: prefixPath("create/", {
                base: ""
            })
        }),
        architects: prefixPath("architects/", {
            architect: prefixPath("architect/", {
                base: "",
                detail: ":architectId([\\w-]+)/",
                promotions: "promotions/"
            }),
            gallery: prefixPath("gallery/", {
                base: ""
            })
        }),
        cookies: prefixPath("cookies/", {
            base: ""
        }),
        opinions: prefixPath("opinions/", {
            opinion: prefixPath("opinion/", {
                base: ""
            })
        }),
        regions: prefixPath("regions/", {
            architects: prefixPath("architects/", {
                base: ""
            })
        })
    }),
    credit: prefixPath("mortgages/", {
        base: "",
        application: prefixPath("applications/", {
            create: "create/"
        })
    })
});

export const apiLink = {
    application: {
        detail: createLinkV1<{uuid: string}>(apiPath.applications.application.detail),
        create: createLinkV1(apiPath.applications.create.base),
        cancel: createLinkV1<{uuid: string}>(apiPath.applications.cancel.detail.base),
        update: createLinkV1<{uuid: string}>(apiPath.applications.update.detail.base),
        sent: createLinkV1(apiPath.applications.sent.base)
    },
    article: {
        list: createLinkV1(apiPath.articles.article.base),
        detail: createLinkV1<IApiArticleParams>(apiPath.articles.article.detail.base),
        rate: createLinkV1(apiPath.articles.article.rate),
        archive: createLinkV1(apiPath.articles.archive.base),
        category: createLinkV1(apiPath.articles.category.base)
    },
    author: {
        list: createLinkV1(apiPath.articles.author.base),
        detail: createLinkV1<IApiAuthorParams>(apiPath.articles.author.detail.base),
        detailAsk: createLinkV1<IApiAuthorParams>(apiPath.articles.author.detail.ask)
    },
    building: {
        list: createLinkV1(apiPath.buildings.building.base),
        detail: createLinkV1<{buildingId: number}>(apiPath.buildings.building.detail.base)
    },
    comments: {
        base: createLinkV1(apiPath.comments.article.base)
    },
    contact: {
        contractor: createNoParamLink(apiPath.contact.our_contractor)
    },
    cookies: {
        base: createLinkV1(apiPath.cookies.base)
    },
    clipPhones: {
        property: createLinkV1<{propertyId: number}>(apiPath.clipPhones.property)
    },
    favourite: {
        base: createLinkV1(apiPath.favourites.base),
        favourite: createLinkV1(apiPath.favourites.favourite.base)
    },
    floor: {
        list: createLinkV1(apiPath.floors.floor.base),
        detail: createLinkV1<{floorId: number}>(apiPath.floors.floor.detail.base)
    },
    hidden: {
        base: createLinkV1(apiPath.hidden.base),
        hidden: createLinkV1(apiPath.hidden.hidden.base)
    },
    marketplace: {
        application: {
            create: createNoParamLink(apiPath.marketplace.applications.create.base)
        },
        architect: {
            list: createNoParamLink(apiPath.marketplace.architects.architect.base),
            detail: createLinkV1<{architectId: number}>(apiPath.marketplace.architects.architect.detail),
            promotions: createNoParamLink(apiPath.marketplace.architects.architect.promotions)
        },
        cookies: {
            base: createNoParamLink(apiPath.marketplace.cookies.base)
        },
        gallery: {
            base: createNoParamLink(apiPath.marketplace.architects.gallery.base)
        },
        opinion: {
            base: createNoParamLink(apiPath.marketplace.opinions.opinion.base)
        },
        region: {
            list: createNoParamLink(apiPath.marketplace.regions.architects.base)
        }
    },
    newsletter: {
        unsubscribe: {
            mailing: createLinkV1<{emailHash: string; verificationHash: string}>(apiPath.newsletter.unsubscribe.mailing),
            sms: createLinkV1<{subscriberUuid: string}>(apiPath.newsletter.unsubscribe.sms)
        }
    },
    offer: {
        list: createLinkV1(apiPath.offers.offer.base),
        detail: createLinkV1<{offerId: number}>(apiPath.offers.offer.detail.base)
    },
    opendays: {
        campaign: {
            detail: createLinkV1<{campaignUuid: string}>(apiPath.opendays.campaign.detail)
        }
    },
    our_offer: createLinkV1(apiPath.our_offer),
    planner: {
        buildings: {
            detail: createLinkV1<{buildingUuid: string}>(apiPath.planner.buildings.detail.base)
        },
        estate: {
            detail: createLinkV1<{propertyUuid: string}>(apiPath.planner.estates.detail.base)
        }
    },
    price_reports: {
        detail: createLinkV1<IApiPriceReportsParams>(apiPath.users.profile.reports.detail)
    },
    property: {
        list: createLinkV1(apiPath.properties.property.base),
        detail: createLinkV1<{propertyId: number}>(apiPath.properties.property.detail.base)
    },
    promotion: {
        list: createLinkV1(apiPath.promotions.promotion.base)
    },
    ranking: {
        base: createLinkV1(apiPath.ratings.partners),
        quarterly: createLinkV1(apiPath.ratings.quarterly),
        old: createLinkV1(apiPath.ratings.old)
    },
    search: {
        for_investor: createLinkV1(apiPath.search.for_investor)
    },
    sessions: {
        userInfo: createLinkV1(apiPath.sessions.user_info)
    },
    stats: {
        investment_offers: createLinkV1(apiPath.stats.investment_offers)
    },
    user: {
        profile: createLinkV1(apiPath.users.profile.base),
        preferences: createLinkV1(apiPath.users.preferences),
        login: createLinkV1(apiPath.users.login),
        logout: createLinkV1(apiPath.users.logout),
        notifications: createLinkV1(apiPath.users.notifications),
        register: createLinkV1(apiPath.users.register),
        register2: createLinkV1(apiPath.users.register2),
        password: {
            change: createLinkV1(apiPath.users.password.change),
            request: createLinkV1(apiPath.users.password.reset.request),
            confirm: createLinkV1(apiPath.users.password.reset.confirm),
            verify: createLinkV1(apiPath.users.password.reset.verify)
        }
    },
    vendor: {
        base: createLinkV1(apiPath.vendors.vendor.base),
        detail: createLinkV1<{vendorId: number}>(apiPath.vendors.vendor.detail.base),
        group: {
            list: createLinkV1<{vendorId: number}>(apiPath.vendors.vendor.detail.group.base)
        }
    },
    variables: createLinkV1(apiPath.variables)
};

/**
 * Params Interfaces
 */

interface IApiArticleParams {
    articleId: number;
}
interface IApiAuthorParams {
    authorId: number;
}
interface IApiPriceReportsParams {
    reportId: string;
}

/**
 * Helper
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
function createLinkV1<TParams = null>(apiPathElement: string) {
    const compiler = pathToRegexp.compile(apiPathElement);
    return <TQuery>(dataQuery: TDataQuery<TQuery>) => {
        const includeParams = appendIncludeParams("", dataQuery);
        return (apiPathParams: TParams): string => compiler(apiPathParams as unknown as Record<string, any>) + includeParams;
    };
}

const appendIncludeParams = <T>(pathname: string, dataQuery: TDataQuery<T>): string => {
    if (isEmpty(dataQuery)) {
        return pathname;
    }

    const includes: string[] = [];
    const addParams = (query: Record<string, any>, parentKey?: string) => {
        const prefix = parentKey ? `${parentKey}.` : "";

        if (parentKey && isEmpty(query)) {
            // set default when keys are not defined
            return includes.push(`i[]=${prefix}*`);
        }

        // keys are defined - append them to array
        Object.keys(query).forEach((key: string) => {
            const currentKey = prefix + key;
            if (isObject(query[key])) {
                // we have deep object
                return addParams(query[key] as Record<string, any>, currentKey);
            } else if (query[key]) {
                // we have boolean value - add when truthly
                return includes.push(`i[]=${currentKey}`);
            }
        });
    };

    addParams(dataQuery); // fills `includes` array
    return `${pathname}?${includes.join("&")}`;
};

const isEmpty = (obj: Record<any, any>) => Object.keys(obj).length === 0 && obj.constructor === Object;
const isObject = (obj: Record<any, any> | boolean) => typeof obj === "object" && obj !== null;
