import * as pathToRegexp from "path-to-regexp";

import {prefixPath} from "@pg-mono/prefix-path";
import {appendQueryString} from "@pg-mono/request";

const kmApiPath = prefixPath("/api/", {
    signup: "signup/",
    login: "login/",
    resetPassword: prefixPath("reset-password/", {
        base: "",
        token: ":token([\\w-]+)/"
    }),
    thirdPartyLogin: "third-party-login/",
    unsubscribe: prefixPath("unsubscribe/", {
        cpl: prefixPath("cpl/", {
            base: ""
        }),
        mailing: prefixPath("mailing/", {
            getLink: "get-link/",
            base: ":emailHash/:verificationHash/"
        })
    }),
    recommendations: prefixPath("recommendations/", {
        state: "state/",
        recommendation: "recommendation/",
        markAsSeen: "mark-as-seen/",
        markAsDismissed: "mark-as-dismissed/",
        markAsApplied: "mark-as-application-sent/",
        markAsFavourite: "mark-as-favorite/",
        feedback: "send-feedback/",
        restore: "restore-from-dismissed/",
        featureFeedback: "feature-feedback/",
        sendFeatureFeedback: "send-feature-feedback/"
    }),
    applications: prefixPath("applications/", {
        application: "application/"
    }),
    user: prefixPath("user/", {
        changePassword: "change-password/",
        info: "info/",
        logout: "logout/",
        preferences: "preferences/"
    }),
    variables: "variables/"
});

export const kmApiLink = {
    signup: () => kmApiPath.signup,
    login: () => kmApiPath.login,
    resetPassword: {
        base: () => kmApiPath.resetPassword.base,
        token: createLink<{token: string}>(kmApiPath.resetPassword.token)
    },
    thirdPartyLogin: () => kmApiPath.thirdPartyLogin,
    unsubscribe: {
        cpl: {
            base: () => kmApiPath.unsubscribe.cpl.base
        },
        mailing: {
            base: createLink<{emailHash: string; verificationHash: string}>(kmApiPath.unsubscribe.mailing.base),
            getLink: kmApiPath.unsubscribe.mailing.getLink
        }
    },
    recommendations: {
        state: () => kmApiPath.recommendations.state,
        recommendation: () => kmApiPath.recommendations.recommendation,
        markAsSeen: () => kmApiPath.recommendations.markAsSeen,
        markAsDismissed: () => kmApiPath.recommendations.markAsDismissed,
        markAsApplied: () => kmApiPath.recommendations.markAsApplied,
        markAsFavourite: () => kmApiPath.recommendations.markAsFavourite,
        feedback: () => kmApiPath.recommendations.feedback,
        restore: () => kmApiPath.recommendations.restore,
        featureFeedback: () => kmApiPath.recommendations.featureFeedback,
        sendFeatureFeedback: () => kmApiPath.recommendations.sendFeatureFeedback
    },
    applications: {
        application: () => kmApiPath.applications.application
    },
    user: {
        changePassword: () => kmApiPath.user.changePassword,
        info: () => kmApiPath.user.info,
        logout: () => kmApiPath.user.logout,
        preferences: () => kmApiPath.user.preferences
    },
    variables: () => kmApiPath.variables
};

function createLink<TParams = null>(apiPathElement: string) {
    const compiler = pathToRegexp.compile(apiPathElement);
    return (apiPathParams: TParams): string => compiler(apiPathParams as unknown as Record<string, string>);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function createLinkWithScenario<TScenario, TParams>(apiPathElement: string) {
    type ParamsType = TParams | null;
    const compiler = pathToRegexp.compile(apiPathElement);
    return (scenario: TScenario, apiPathParams: ParamsType = null): string =>
        appendQueryString(compiler(apiPathParams as unknown as Record<string, string>), {s: scenario as unknown as string});
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function createNoParamLinkWithScenario<TScenario>(apiPathElement: string) {
    return (scenario: TScenario): string => appendQueryString(apiPathElement, {s: scenario as unknown as string});
}

export enum Scenario {}
