import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const RoomHeightIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M6.867 4.356 4.31 1.8l-.377.377 2.4 2.4v3.89h-4.8v-3.89l2.4-2.4.377-.377-.377-.377L1 4.356V9h5.867V4.356ZM7.4 1v.533h1.067v6.934H7.4V9H9V1H7.4Z" />
        </SvgIcon>
    );
};
