// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@layer packages {
    .vhStpSdebofS_GeXH28o {
        background-color: var(--colors-gray-300);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .T2oE1tC9a2411EWU79X_ {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(6);
        fill: var(--colors-gray-700);
        max-width: 100%;
    }
}
`, "",{"version":3,"sources":["webpack://./../../packages/design_system_modules/core/image/src/components/Placeholder.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,wCAAwC;QACxC,WAAW;QACX,YAAY;QACZ,kBAAkB;QAClB,MAAM;QACN,OAAO;IACX;;IAEA;QACI,kBAAkB;QAClB,QAAQ;QACR,SAAS;QACT,yCAAyC;QACzC,4BAA4B;QAC5B,eAAe;IACnB;AACJ","sourcesContent":["@layer packages {\n    .placeholderBase {\n        background-color: var(--colors-gray-300);\n        width: 100%;\n        height: 100%;\n        position: absolute;\n        top: 0;\n        left: 0;\n    }\n\n    .placeholderIcon {\n        position: absolute;\n        top: 50%;\n        left: 50%;\n        transform: translate(-50%, -50%) scale(6);\n        fill: var(--colors-gray-700);\n        max-width: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export var placeholderBase = `vhStpSdebofS_GeXH28o`;
export var placeholderIcon = `T2oE1tC9a2411EWU79X_`;
export default ___CSS_LOADER_EXPORT___;
