import {prefixPath} from "@pg-mono/prefix-path";

import {createLink} from "./utils/create_link";
import {createNoParamLink} from "./utils/create_no_param_link";
import {V1_API_PREFIX} from "./api_prefixes";

export const mpPublicApiPaths = prefixPath(V1_API_PREFIX, {
    marketplace: prefixPath("marketplace/", {
        applications: prefixPath("applications/", {
            modularHouses: prefixPath("modular-houses/", {
                create: "create/"
            })
        }),
        modularHouses: prefixPath("modular-houses/", {
            offer: prefixPath("offer/", {
                list: "",
                detail: ":offerId(\\d+)/"
            })
        }),
        regions: prefixPath("regions/", {
            architects: "architects/",
            modularHouses: "modular-houses/"
        })
    })
});

export const mpPublicApiLinks = {
    marketplace: {
        applications: {
            modularHouses: {
                create: createNoParamLink(mpPublicApiPaths.marketplace.applications.modularHouses.create)
            }
        },
        modularHouses: {
            offer: {
                list: createNoParamLink(mpPublicApiPaths.marketplace.modularHouses.offer.list),
                detail: createLink<{offerId: number}>(mpPublicApiPaths.marketplace.modularHouses.offer.detail)
            }
        },
        regions: {
            architects: createNoParamLink(mpPublicApiPaths.marketplace.regions.architects),
            modularHouses: createNoParamLink(mpPublicApiPaths.marketplace.regions.modularHouses)
        }
    }
};
