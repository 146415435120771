// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@layer packages {
    .dChQ5kI6Dmg0KmUpR_aA {
        --iconFill: black;
        --iconSize: 1;
        fill: var(--iconFill);
        transform: scale(var(--iconSize));
        width: 10px;
        height: 10px;
    }
}
`, "",{"version":3,"sources":["webpack://./../../packages/design_system_modules/core/icons/src/components/SvgIcon.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,iBAAiB;QACjB,aAAa;QACb,qBAAqB;QACrB,iCAAiC;QACjC,WAAW;QACX,YAAY;IAChB;AACJ","sourcesContent":["@layer packages {\n    .svgIconBase {\n        --iconFill: black;\n        --iconSize: 1;\n        fill: var(--iconFill);\n        transform: scale(var(--iconSize));\n        width: 10px;\n        height: 10px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export var svgIconBase = `dChQ5kI6Dmg0KmUpR_aA`;
export default ___CSS_LOADER_EXPORT___;
