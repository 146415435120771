import {useRouteMatch} from "react-router";

import {kmAppPath, SiteSlug} from "@pg-mono/km-routes";

import {ghRedirectUrl, rpRedirectUrl} from "../app/utils/read_environment_variables";

const logoGH = require("../app/images/logo_gh_default.png");
const logoRP = require("../app/images/logo_rp_default.svg");

export const useRefererData = () => {
    const currentSite = useRouteMatch<{site: string}>(kmAppPath.site.base)?.params.site;

    switch (currentSite) {
        case SiteSlug.GH: {
            return {
                logo: logoGH,
                redirectUrl: ghRedirectUrl,
                slug: SiteSlug.GH
            };
        }
        case SiteSlug.RP: {
            return {
                logo: logoRP,
                redirectUrl: rpRedirectUrl,
                slug: SiteSlug.RP
            };
        }
        default: {
            return {
                logo: logoRP,
                redirectUrl: rpRedirectUrl,
                slug: SiteSlug.KM
            };
        }
    }
};
