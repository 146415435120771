import type {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";

import {IPropertyDetail} from "../types/IPropertyDetail";
import {PageChangeType} from "../types/PageChangeType";

export interface IRecommendationDetailsModalStore {
    property: IPropertyDetail | null;
    isRecommended: boolean;
    recommendationID: number;
    pageChangeType: PageChangeType | null;
    listIndex: number;
    applicationSent: boolean;
    visibleStatus: number;
    status: number;
}

const initialState: IRecommendationDetailsModalStore = {
    property: null,
    isRecommended: false,
    recommendationID: 0,
    pageChangeType: null,
    listIndex: 0,
    applicationSent: false,
    visibleStatus: 0,
    status: 0
};

export const recommendationDetailsModalSlice = createSlice({
    name: "recommendationDetailsModal",
    initialState,
    reducers: {
        setRecommendationDetailsModal: (state, action: PayloadAction<IRecommendationDetailsModalStore>) => {
            return {...state, ...action.payload};
        }
    }
});

export const {setRecommendationDetailsModal} = recommendationDetailsModalSlice.actions;
