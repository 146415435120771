import {useEffect} from "react";

import {useReadRecommendationsStateQuery} from "../../modules/properties/api/recommendations_api";
import {recommendationsAnalytics} from "../../modules/properties/tracking/recommendations_analytics";

export const useGlobalAnalyticsForAuthUser = () => {
    const {data} = useReadRecommendationsStateQuery();

    useEffect(() => {
        if (data) {
            const {recommended_count, sold_recommendations_count} = data.stats || {
                recommended_count: 0,
                sold_recommendations_count: 0
            };

            const recommendedCount = recommended_count - sold_recommendations_count;
            recommendationsAnalytics.gtm.recommendationCountEvent(recommendedCount);
        }
    }, [data]);
};
