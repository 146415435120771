import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const GalleryFillIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M9 7.604V2.801H7.617l-.749-.749L6.816 2H4.25l-.047.047-.75.754H1v5.33h8v-.527ZM2.601 4.132a.267.267 0 1 1-.534 0 .267.267 0 0 1 .534 0Zm1.59 1.07a1.339 1.339 0 1 1 2.678 0 1.339 1.339 0 0 1-2.678 0Z" />
        </SvgIcon>
    );
};
