// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.y4XgkeeBEAUT3NQ_gWye {
    margin-right: 2rem;
}
`, "",{"version":3,"sources":["webpack://./../../packages/design_system_modules/forms/select/src/components/Select.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB","sourcesContent":[".iconStyle {\n    margin-right: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
export var iconStyle = `y4XgkeeBEAUT3NQ_gWye`;
export default ___CSS_LOADER_EXPORT___;
