// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wBtUmq37OKH_bpz1mvg_ {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
}

.twiBLuI0mfKx3ipAbouJ {
    margin-bottom: 1rem;

}

.KJi0g_Pnh00rUav5hK3h {
    display: inline-flex;
    margin-right: 1rem;
    margin-left: 1rem;
    flex: 1 1 auto;

    &.TkcfWflQo88rZee3xaXc {
        margin-left: 0;
    }

    &.P4GJ1agnG8Yf9kvGtYaX {
        margin-right: 0;
    }
}
`, "",{"version":3,"sources":["webpack://./../../packages/design_system_modules/forms/inputs/src/components/range_input/RangeInput.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,mBAAmB;;AAEvB;;AAEA;IACI,oBAAoB;IACpB,kBAAkB;IAClB,iBAAiB;IACjB,cAAc;;IAEd;QACI,cAAc;IAClB;;IAEA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".rangeHolder {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    max-width: 100%;\n}\n\n.separator {\n    margin-bottom: 1rem;\n\n}\n\n.inputHolder {\n    display: inline-flex;\n    margin-right: 1rem;\n    margin-left: 1rem;\n    flex: 1 1 auto;\n\n    &.range-input-lower {\n        margin-left: 0;\n    }\n\n    &.range-input-upper {\n        margin-right: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export var rangeHolder = `wBtUmq37OKH_bpz1mvg_`;
export var separator = `twiBLuI0mfKx3ipAbouJ`;
export var inputHolder = `KJi0g_Pnh00rUav5hK3h`;
var _1 = `TkcfWflQo88rZee3xaXc`;
export { _1 as "range-input-lower" };
var _2 = `P4GJ1agnG8Yf9kvGtYaX`;
export { _2 as "range-input-upper" };
export default ___CSS_LOADER_EXPORT___;
