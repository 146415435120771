import React, {ReactNode} from "react";
import {css} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {borderRadius, calculateRemSize, flex, flexAbsoluteCenter, flexDirection, mt, onDesktop, p} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

interface IProps {
    title?: string;
    children?: ReactNode;
    cancelButton?: ReactNode;
    okButtonText?: string;
    cancelButtonText?: string;
    onCancel?: () => void;
    onConfirmation?: () => void;
}

export const ModalConfirmLayoutCentered = (props: IProps) => {
    const {title, children, cancelButton, okButtonText, cancelButtonText, onCancel, onConfirmation} = props;

    return (
        <div css={innerWrap}>
            {title && (
                <Text mb="1.2rem" variant="headline_3" align="center">
                    {title}
                </Text>
            )}

            {children && (
                <Text as="div" variant="body_copy_1" align="center">
                    {children}
                </Text>
            )}

            <div css={buttonsWrapperStyle}>
                <Button variant="filled_primary" onClick={onConfirmation} css={buttonStyle}>
                    {okButtonText ?? "Potwierdzam"}
                </Button>
                {cancelButton || (
                    <Button variant="outlined_secondary" onClick={onCancel} css={buttonStyle}>
                        {cancelButtonText ?? "Anuluj"}
                    </Button>
                )}
            </div>
        </div>
    );
};

const innerWrap = css`
    position: relative;
    max-height: 100vh;
    height: 100%;
    max-width: 100vw;
    overflow: auto;
    ${flexAbsoluteCenter};
    ${flexDirection("column")};
    ${p(7, 2)};

    ${onDesktop(css`
        ${p(7, 4, 4, 4)};
        ${borderRadius()};
    `)};
`;

const buttonsWrapperStyle = css`
    ${flex("center", "center")};
    ${flexDirection("column")};
    ${mt(3)};
    gap: ${calculateRemSize(2)};

    ${onDesktop(css`
        ${mt(5)};
        ${flexDirection("row")};
    `)};
`;

const buttonStyle = css`
    width: 100%;

    ${onDesktop(css`
        width: auto;
        min-width: 23rem;
    `)};
`;
