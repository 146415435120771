import React from "react";
import {createRoot} from "react-dom/client";
import {BrowserRouter} from "react-router-dom";

import {getBugsnagErrorBoundary, initClientBugsnag} from "@pg-mono/bugsnag-client";

import {App} from "../../common/app/App";
import {bugsnagReleaseStage} from "../../common/app/utils/read_environment_variables";

initClientBugsnag("c7ea0b6e3135b76b495c67002d8d2249", bugsnagReleaseStage);

const BugsnagErrorBoundary = getBugsnagErrorBoundary();

export const renderClientApp = () => {
    const root = createRoot(document.getElementById("root") as HTMLElement);

    root.render(
        <BugsnagErrorBoundary>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </BugsnagErrorBoundary>
    );
};
