import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const ExpandIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M.668 0H0v3.333h.667V0Z" />
            <path d="M3.333.666V0H0v.666h3.333ZM10 .666V0H6.667v.666H10Z" />
            <path d="M9.334 3.333H10V0h-.666v3.333ZM9.334 9.999H10V6.665h-.666V10Z" />
            <path d="M6.667 9.332V10H10v-.667H6.667ZM.904.431l-.47.471 3.153 3.154.471-.47L.904.43Z" />
            <path d="m6.413 5.942-.47.47 3.153 3.155.472-.471-3.155-3.154ZM4.059 6.412l-.471-.47L2.01 7.517.434 9.095l.47.472L4.06 6.412ZM9.568.902l-.47-.47-3.155 3.153.471.471L9.568.902Z" />
            <path d="M.001 9.332V10h3.334v-.667H0Z" />
            <path d="M.668 6.665H0v3.333h.667V6.665Z" />
        </SvgIcon>
    );
};
