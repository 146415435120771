// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@layer packages {
    .QLS_RnJl5Mw5lm6bue0a {
        padding: 0;
    }

    .CmoNvJyE6RmdlvgiU5kV {
        display: flex;
        width: 100%;
        flex-basis: 100%;
    }

    .HwjHLKayTQIIbkkObdF_ {
        min-width: 14rem;
        border: none;
        z-index: 10000;
    }

    .aEfPE0QLByCerk0vaXID {
        width: 100%;
        flex-basis: 100%;
        -moz-appearance: textfield;
    }

    /*
    * remove browser specific number selection arrows as they make no sense in a phone input
    */
    .aEfPE0QLByCerk0vaXID::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}
`, "",{"version":3,"sources":["webpack://./../../packages/design_system_modules/forms/phone_input/src/PhoneField.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,UAAU;IACd;;IAEA;QACI,aAAa;QACb,WAAW;QACX,gBAAgB;IACpB;;IAEA;QACI,gBAAgB;QAChB,YAAY;QACZ,cAAc;IAClB;;IAEA;QACI,WAAW;QACX,gBAAgB;QAChB,0BAA0B;IAC9B;;IAEA;;KAEC;IACD;QACI,wBAAwB;QACxB,SAAS;IACb;AACJ","sourcesContent":["@layer packages {\n    .wrapper {\n        padding: 0;\n    }\n\n    .innerWrapper {\n        display: flex;\n        width: 100%;\n        flex-basis: 100%;\n    }\n\n    .selectStyle {\n        min-width: 14rem;\n        border: none;\n        z-index: 10000;\n    }\n\n    .inputStyle {\n        width: 100%;\n        flex-basis: 100%;\n        -moz-appearance: textfield;\n    }\n\n    /*\n    * remove browser specific number selection arrows as they make no sense in a phone input\n    */\n    .inputStyle::-webkit-inner-spin-button {\n        -webkit-appearance: none;\n        margin: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = `QLS_RnJl5Mw5lm6bue0a`;
export var innerWrapper = `CmoNvJyE6RmdlvgiU5kV`;
export var selectStyle = `HwjHLKayTQIIbkkObdF_`;
export var inputStyle = `aEfPE0QLByCerk0vaXID`;
export default ___CSS_LOADER_EXPORT___;
