/**
 * Server Response Header Service
 *
 * `appendHeaders` is used in request.ts to add response headers from Rest API
 * `fetchHeaders`  is used in render-handler to send response headers to client
 */
import {Headers} from "node-fetch";

import {getValidatedCookieWithDomain} from "../validate_cookie_domain";

const API_RESPONSE_HEADER_KEYS = ["Set-Cookie"].map((str) => str.toLocaleLowerCase());

export interface IServerResponseHeadersService {
    appendHeaders: (headerClass: Headers) => void;
    getHeaders: () => IHeaders;
    validateCookieDomain: (domain: string) => void;
}

type IHeaders = Record<string, string | string[]>;

export const createServerResponseHeadersService = (initHeaders: IHeaders): IServerResponseHeadersService => {
    let _responseHeaders: IHeaders = initHeaders;

    return {
        appendHeaders: (headerClass: Headers): void => {
            // `headerClass` is fetch's response.headers class
            if (process.env.EXEC_ENV === "browser") {
                return; // noop for browser
            }

            const rawHeaders: Record<string, string | string[]> = headerClass && isFunction(headerClass.raw) ? headerClass.raw() : {};
            if (isEmptyObject(rawHeaders)) {
                return;
            }

            const appendHeaders = cleanObject(rawHeaders);

            if (
                _responseHeaders["set-cookie"] &&
                appendHeaders["set-cookie"] &&
                Array.isArray(_responseHeaders["set-cookie"]) &&
                Array.isArray(appendHeaders["set-cookie"])
            ) {
                // we need to collect all set-cookie header values
                const combinedCookies: string[] = [..._responseHeaders["set-cookie"], ...appendHeaders["set-cookie"]];
                const combinedValidCookies = combinedCookies.filter((cookie) => cookie.indexOf("=") !== -1);
                const combinedValidNames = combinedValidCookies.map((value) => value.slice(0, value.indexOf("=")));
                const finalCookies = combinedValidCookies.reduce((cookiesAcc, value, idx) => {
                    const name = combinedValidNames[idx];
                    // test whether cookie name repeats itself, if so use the latter value
                    if (combinedValidNames.slice(idx + 1).includes(name)) {
                        // specific cookie name is present more than once
                        return cookiesAcc; // get rid of this cookie entry
                    } else {
                        return [...cookiesAcc, value];
                    }
                }, [] as string[]);
                _responseHeaders = {..._responseHeaders, ...appendHeaders, "set-cookie": finalCookies};
            } else {
                _responseHeaders = {..._responseHeaders, ...appendHeaders};
            }
        },
        validateCookieDomain: (domain) => {
            if (process.env.EXEC_ENV === "browser") {
                return; // noop for browser
            }
            // validate cookies
            _responseHeaders["set-cookie"] = Array.isArray(_responseHeaders["set-cookie"])
                ? _responseHeaders["set-cookie"].map((cookie) => getValidatedCookieWithDomain(cookie, domain))
                : getValidatedCookieWithDomain(_responseHeaders["set-cookie"], domain);
        },
        getHeaders: () => {
            if (process.env.EXEC_ENV === "browser") {
                throw new Error("ServerResponseHeaders fetchHeaders is server only function");
            }

            return pick(_responseHeaders, API_RESPONSE_HEADER_KEYS) as IHeaders;
        }
    };
};

/**
 * Helpers
 */
const isEmptyObject = (obj: Record<string, unknown>) => Object.keys(obj).length === 0 && obj.constructor === Object;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const isEmptyArray = (arr: unknown[]) => arr != null && arr.length > 0;
const isFunction = (fun: unknown) => typeof fun === "function";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const isObject = (obj: unknown) => typeof obj === "object" && obj !== null;
const pick = (obj: Record<string, unknown>, keys: string[]) =>
    Object.entries(obj)
        .filter(([key]) => keys.includes(key))
        .reduce((obj, [key, val]) => Object.assign(obj, {[key]: val}), {});
const cleanObject = <T>(obj: T): T => {
    for (const propName in obj) {
        if (!obj[propName]) {
            delete obj[propName];
        }
    }
    return obj;
};
