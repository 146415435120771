import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const FloorsIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M8.195 4.097 5.097 1 2 4.097V9h6.195V4.097Zm-.962-.165H2.96l2.136-2.136 2.136 2.136Zm.398.563v1.69H2.563v-1.69H7.63ZM2.563 8.437v-1.69H7.63v1.69H2.563Z" />
        </SvgIcon>
    );
};
