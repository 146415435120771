const MAX_IMPRESSIONS_PER_HIT = 10;

export interface IGtmData {
    event: string;
    button?: string;
    ecommerce?: {
        checkout?: unknown;
        currencyCode?: unknown;
        impressions?: object[];
        purchase?: unknown;
    };
    action?: string;
    label?: string | number;
}

export const hitGoogleTagManager = <GtmData extends IGtmData>(gtmData: GtmData) => {
    // console.log("hitGoogleTagManager: ", (gtmData as any).event, gtmData); // eslint-disable-line @typescript-eslint/no-explicit-any
    const win = window as Window;

    win.dataLayer = win.dataLayer || [];
    if ((gtmData.ecommerce?.impressions?.length ?? 0) > MAX_IMPRESSIONS_PER_HIT) {
        // start impressions chunking
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const impressions = gtmData!.ecommerce!.impressions!; // because we validate that field earlier
        const chunks = Math.ceil(impressions.length / MAX_IMPRESSIONS_PER_HIT);
        for (let i = 0; i < chunks; ++i) {
            const chunkData = structuredClone(gtmData);
            if (chunkData.ecommerce) {
                chunkData.ecommerce.impressions = impressions.slice(i * MAX_IMPRESSIONS_PER_HIT, (i + 1) * MAX_IMPRESSIONS_PER_HIT);
            }
            win.dataLayer.push(chunkData);
        }
    } else {
        win.dataLayer.push(gtmData);
    }
};
