import React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {elevation} from "@pg-design/elevation";
import {backgroundColor, calculateRemSize, flex, flexDirection, ml, mr, mt, p, position, w100, zIndex} from "@pg-design/helpers-css";
import {ArrowIcon} from "@pg-design/icons-module";
import {useIsMobile} from "@pg-mono/hooks";

import {useRefererData} from "../../../hooks/use_referer_data";
import {HamburgerMenu} from "./HamburgerMenu";
import {NavigationAccountSettings} from "./NavigationAccountSettings";
import {NavigationLinks} from "./NavigationLinks";
import {RefererPortalLink} from "./RefererPortalLink";

export const Navigation = () => {
    const {logo} = useRefererData();
    const isMobile = useIsMobile();

    const linkedLogo = () => {
        return (
            <RefererPortalLink>
                <img src={logo} height={40} alt="logo" />
            </RefererPortalLink>
        );
    };

    return (
        <div className="Navigation">
            {isMobile ? (
                <NavigationMobile>
                    <div css={ml(2)}>{linkedLogo()}</div>

                    <div css={mr(10)}>
                        <RefererPortalLink>
                            <ArrowIcon size="3" />
                        </RefererPortalLink>
                    </div>

                    <HamburgerMenu />
                </NavigationMobile>
            ) : (
                <NavigationDesktop>
                    {linkedLogo()}

                    <div css={navigation}>
                        <NavigationLinks />

                        <NavigationAccountSettings />
                    </div>
                </NavigationDesktop>
            )}
        </div>
    );
};

const NavigationDesktop = styled.div`
    ${backgroundColor("#fff")};
    ${position("fixed")};
    top: 0;
    left: 0;
    ${elevation(4)};
    width: ${calculateRemSize(25)};
    height: 100vh;
    ${p(3, 2)};
    ${flex()};
    ${flexDirection("column")};
    ${zIndex(5)};
`;

const navigation = css`
    ${flex("normal", "space-between")};
    ${flexDirection("column")};
    flex-grow: 1;
    ${mt(4)};
`;

const NavigationMobile = styled.div`
    ${flex("center", "space-between")};
    ${backgroundColor("#fff")};
    height: ${calculateRemSize(8)};
    ${w100};
`;
