import {FC, FormEvent, ReactNode} from "react";
import {Form, Formik, FormikConfig, FormikProps, FormikValues, useField, useFormikContext} from "formik";

import {Button, IButtonProps} from "@pg-design/button-module";
import {Checkbox, ICheckboxProps} from "@pg-design/checkbox";
import {FileField, IFileFieldProps} from "@pg-design/inputs-module";
import {IPhoneFieldProps, PhoneField} from "@pg-design/phone-input-module";
import {ISelectGroupMultiFieldProps, SelectGroupMultiField} from "@pg-design/select-group";

import {IInputProps, Input} from "./fields/input/Input";
import {ITextAreaProps, TextArea} from "./fields/text_area/TextArea";
import {getFormikFieldProps} from "./get_formik_field_props";
import {OnChangeHandlingComponent} from "./OnChangeHandlingComponent";
import {IFormikSubmitFn} from "./types";

interface IProps<TValues> extends FormikConfig<TValues> {
    children: ReactNode | IRenderPropsChildren<TValues>;
    onSubmit: IFormikSubmitFn<TValues>;
    onChange?: (formValues: TValues) => void;
    onFocus?: React.FocusEventHandler<HTMLFormElement> | undefined;
    initialValues: TValues;
    validationSchema?: FormikConfig<TValues>["validationSchema"];
    className?: string;
}

type IRenderPropsChildren<TValues> = (formikProps: FormikProps<TValues>) => ReactNode;

export const FormikForm = <T extends FormikValues>(props: IProps<T>) => {
    const {children, className, ...formikProps} = props;

    return (
        <Formik {...formikProps}>
            {(formProps) => {
                return (
                    <>
                        {props.onChange && <OnChangeHandlingComponent onChange={props.onChange} />}
                        <Form className={className} onFocus={props.onFocus}>
                            {typeof children === "function" ? children(formProps) : children}
                        </Form>
                    </>
                );
            }}
        </Formik>
    );
};

export type IFieldProps<StandardFieldProps> = Omit<StandardFieldProps, "onChange" | "name" | "onAfterChange" | "value" | "error" | "checked"> & {
    name: string;
    className?: string;
};

const FormikInput = <TValues extends Record<string, unknown>>(props: IFieldProps<IInputProps>) => {
    const formikProps = useFormikContext<TValues>();
    return <Input {...props} {...getFormikFieldProps<TValues>(formikProps, props.name)} className={props.className} />;
};
FormikForm.Input = FormikInput;

const FormikPhoneField = <TValues extends Record<string, unknown>>(props: IFieldProps<IPhoneFieldProps>) => {
    const formikProps = useFormikContext<TValues>();

    const [, meta] = useField(props.name);

    const error = meta.error && meta.touched ? Object.values(meta.error) : undefined;

    return <PhoneField {...props} {...getFormikFieldProps<TValues>(formikProps, props.name)} error={error} className={props.className} />;
};
FormikForm.PhoneField = FormikPhoneField;

const FormikTextarea = <TValues extends Record<string, unknown>>(props: IFieldProps<ITextAreaProps>) => {
    const formikProps = useFormikContext<TValues>();
    return <TextArea {...props} {...getFormikFieldProps<TValues>(formikProps, props.name)} className={props.className} />;
};
FormikForm.Textarea = FormikTextarea;

const FormikCheckbox = <TValues extends Record<string, unknown>>(props: IFieldProps<ICheckboxProps>) => {
    const formikProps = useFormikContext<TValues>();
    // Casting to any because we are spreading props with two different scenarios and TS goes nuts.
    // This should not cause any issues since formik component is just reusing internal checkbox props interface.
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    return <Checkbox {...(props as any)} {...getFormikFieldProps<TValues>(formikProps, props.name)} className={props.className} />;
};
FormikForm.Checkbox = FormikCheckbox;
//

const FormikSelectGroupMultiField = <TValues extends Record<string, string | number>>(props: IFieldProps<ISelectGroupMultiFieldProps<string | number>>) => {
    const formikProps = useFormikContext<TValues>();

    return <SelectGroupMultiField {...props} options={props.options} {...getFormikFieldProps<TValues>(formikProps, props.name)} className={props.className} />;
};
FormikForm.SelectGroupMultiField = FormikSelectGroupMultiField;
//

const FormikButton: FC<IButtonProps> = (props) => {
    const formikProps = useFormikContext();
    return (
        <Button {...props} isLoading={formikProps.isSubmitting || props.isLoading} className={props.className}>
            {props.children}
        </Button>
    );
};
FormikForm.Button = FormikButton;

FormikForm.OnChangeHandlingComponent = OnChangeHandlingComponent;

const FormikFileInput = <TValues extends Record<string, unknown>>(
    props: IFieldProps<IFileFieldProps> & {onChange?: (event: FormEvent<HTMLInputElement>) => void}
) => {
    const formikProps = useFormikContext<TValues>();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {onAfterChange, onChange, ...formikFieldProps} = getFormikFieldProps<TValues>(formikProps, props.name);
    const onChangeHandler = (e: FormEvent<HTMLInputElement>) => {
        const file = e.currentTarget.files ? e.currentTarget.files[0] : undefined;
        onChange?.(e.currentTarget.name, file);

        // It is necessary to allow pass onChange in case of show photo thumbnail
        // or some additional file information outside of component.
        if (props.onChange) {
            props.onChange(e);
        }
    };

    return <FileField {...props} {...formikFieldProps} onChange={onChangeHandler} className={props.className} />;
};
FormikForm.File = FormikFileInput;

export const useFormikUtilsContext = useFormikContext;
export const useFormikUtilsField = useField;
