const EXEC_ENV = process.env.EXEC_ENV;
if (EXEC_ENV == null) {
    throw new Error("isomorphic-fetch: EXEC_ENV is not defined");
}
// import "whatwg-fetch"; // NOTE: this one fails server-side
// import nodeFetch from "node-fetch";
// we need dynamic require (below) instead of static imports (above)
const importedFetch = EXEC_ENV === "browser" ? require("whatwg-fetch").fetch : require("node-fetch").default;

import {RequestMethod} from "./request_methods";

export interface IFetchOptions {
    method: RequestMethod;
    body?: string;
    headers?: Record<string, string>;
    credentials?: "include" | "same-origin" | "cross-origin";
    /** Difference between `include` and `same-origin`:
     * When mobile.rp. hits mobile.rp. (api), response set-cookie is properly set by the browser.
     * When desktop.rp. hits mobile.rp. (api), response set-cookie is:
     *  - properly set by the browser (for `include`)
     *  - not set by the browser (for `same-origin`)
     */
}

export const isomorphicFetch = importedFetch;
