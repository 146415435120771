import React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {flexAbsoluteCenter} from "@pg-design/helpers-css";

interface IProps {
    children: React.ReactNode;
    className?: string;
    height?: string;
}

export const CenterBox: React.FC<IProps> = (props) => {
    return (
        <Wrapper className={props.className} height={props.height}>
            <div css={centeredBox}>{props.children}</div>
        </Wrapper>
    );
};

const Wrapper = styled.div<{height?: IProps["height"]}>`
    display: flex;
    flex-grow: 1;
    height: ${(props) => props.height || "100%"};
    min-height: ${(props) => props.height || "100%"};
`;

const centeredBox = css`
    ${flexAbsoluteCenter};
    flex-direction: column;
    flex: 1 1 0;
`;
