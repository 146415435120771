import {css, Theme} from "@emotion/react";

export const globals = (theme: Theme) => {
    return css`
        html {
            font-size: ${theme.globals.text.root} !important;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }

        body {
            font-size: ${theme.globals.text.size.desktop};
            font-weight: ${theme.globals.text.weight};
            line-height: ${theme.globals.text.lineHeight};
            color: ${theme.globals.text.color};
            background-color: ${theme.globals.body.background};
        }

        html,
        body {
            font-family: ${theme.globals.text.family};
        }

        a {
            color: ${theme.globals.link.color};
            text-decoration: none;

            &:hover,
            &:focus {
                color: ${theme.globals.link.hover.color};
                text-decoration: ${theme.globals.link.hover.decoration};
            }
        }

        input,
        button,
        select,
        textarea {
            font-family: inherit;
            font-size: inherit;
            line-height: inherit;
        }

        [role="button"] {
            cursor: pointer;
        }
    `;
};
