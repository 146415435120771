import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const DotsVerticalIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <circle cx={5} cy={1.3} r={1.25} fill="var(--colors-secondary)" />
            <circle cx={5} cy={5} r={1.25} fill="var(--colors-secondary)" />
            <circle cx={5} cy={8.7} r={1.25} fill="var(--colors-secondary)" />
        </SvgIcon>
    );
};
