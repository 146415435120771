// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@layer packages {
    .iFoGZNOq3XiShoCG2Rvp {
        --ratio-xs: auto;
        aspect-ratio: var(--ratio-xs);

        position: relative;
        line-height: 0;
        display: inline-block;
        overflow: hidden;

        @media screen and (min-width: 1024px) {
            --ratio-md: var(--ratio-xs);
            aspect-ratio: var(--ratio-md);
        }
    }

    .BXEk5w0Vdg41pKRJULVJ {
        display: block;
    }

    .aGcFQmKDGRPEiUMGHD5J {
        max-width: 100%;
    }

    .qpKHWTgqkXlYZg52mlCd {
        --ratio-xs: auto;
        aspect-ratio: var(--ratio-xs);

        display: block;
        max-width: 100%;
        height: auto;
        /* object fit is added because this is how previous ratio hack positioned the image, ie. in OfferBox.
        Putting that here is not perfect, but makes package transition easier */
        object-fit: cover;
        object-position: top;

        @media screen and (min-width: 1024px) {
            --ratio-md: var(--ratio-xs);
            aspect-ratio: var(--ratio-md);
        }
    }
}
`, "",{"version":3,"sources":["webpack://./../../packages/design_system_modules/core/image/src/components/Image.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,gBAAgB;QAChB,6BAA6B;;QAE7B,kBAAkB;QAClB,cAAc;QACd,qBAAqB;QACrB,gBAAgB;;QAEhB;YACI,2BAA2B;YAC3B,6BAA6B;QACjC;IACJ;;IAEA;QACI,cAAc;IAClB;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,gBAAgB;QAChB,6BAA6B;;QAE7B,cAAc;QACd,eAAe;QACf,YAAY;QACZ;+EACuE;QACvE,iBAAiB;QACjB,oBAAoB;;QAEpB;YACI,2BAA2B;YAC3B,6BAA6B;QACjC;IACJ;AACJ","sourcesContent":["@layer packages {\n    .imageWrapper {\n        --ratio-xs: auto;\n        aspect-ratio: var(--ratio-xs);\n\n        position: relative;\n        line-height: 0;\n        display: inline-block;\n        overflow: hidden;\n\n        @media screen and (min-width: 1024px) {\n            --ratio-md: var(--ratio-xs);\n            aspect-ratio: var(--ratio-md);\n        }\n    }\n\n    .imageWrapperBlock {\n        display: block;\n    }\n\n    .imageWrapperError {\n        max-width: 100%;\n    }\n\n    .imageWithRatio {\n        --ratio-xs: auto;\n        aspect-ratio: var(--ratio-xs);\n\n        display: block;\n        max-width: 100%;\n        height: auto;\n        /* object fit is added because this is how previous ratio hack positioned the image, ie. in OfferBox.\n        Putting that here is not perfect, but makes package transition easier */\n        object-fit: cover;\n        object-position: top;\n\n        @media screen and (min-width: 1024px) {\n            --ratio-md: var(--ratio-xs);\n            aspect-ratio: var(--ratio-md);\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export var imageWrapper = `iFoGZNOq3XiShoCG2Rvp`;
export var imageWrapperBlock = `BXEk5w0Vdg41pKRJULVJ`;
export var imageWrapperError = `aGcFQmKDGRPEiUMGHD5J`;
export var imageWithRatio = `qpKHWTgqkXlYZg52mlCd`;
export default ___CSS_LOADER_EXPORT___;
