import classNames from "classnames";

import {highlight} from "./Highlight.module.css";

interface IHighlightProps {
    className?: string;
    children?: React.ReactNode;
    strong?: boolean;
    href?: string;
    color?: string;
    as?: React.ElementType;
}

export const Highlight = (props: IHighlightProps) => {
    const DomElement = (props.href && "a") || props.as || "span";

    const colorStyle = props.color ? {"--highlight-color": props.color} : undefined;

    const className = classNames(highlight, props.className);

    return (
        <DomElement style={colorStyle} className={className} href={props.href}>
            {props.children}
        </DomElement>
    );
};
