import {AxiosResponse} from "axios";

import {kmApiLink} from "@pg-mono/km-routes";

import {IVariables} from "../state/variables_slice";
import axios from "../utils/fetch";

export const fetchVariables = (): Promise<AxiosResponse<IVariables>> => {
    return axios.get(kmApiLink.variables());
};
