import type {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";
import {v4 as uuid} from "uuid";

import {INotification} from "@pg-design/notifications";

const initialState: INotification[] = [];

export const notificationSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        addNotification: (state, action: PayloadAction<Omit<INotification, "timeout" | "id">>) => {
            state.push({...action.payload, id: uuid(), timeout: 5000});
        },
        removeNotification: (state, action: PayloadAction<string>) => {
            return state.filter((notification) => notification.id !== action.payload);
        }
    }
});

export const {addNotification, removeNotification} = notificationSlice.actions;
