import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const WorldSideIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M5 1a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm0 7.467a3.467 3.467 0 1 1 0-6.934 3.467 3.467 0 0 1 0 6.934Z" />
            <path d="M4.733 3.133v.534h1.6v1.6h.534V3.133H4.733ZM3.667 4.733h-.534v2.134h2.134v-.534h-1.6v-1.6Z" />
        </SvgIcon>
    );
};
