import React from "react";

import {useRefererData} from "../../../hooks/use_referer_data";

interface IProps {
    children: React.ReactNode;
    target?: "_blank" | "_self" | "_parent" | "_top";
}

export const RefererPortalLink = (props: IProps) => {
    const {redirectUrl} = useRefererData();

    return (
        <a href={redirectUrl} target={props.target || "_self"}>
            {props.children}
        </a>
    );
};
