// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RHalcmrDHW4VJ5UA6pqM {
    padding: calc(2 * var(--globals-baseUnit));
    border-radius: var(--globals-baseUnit);
    background-color: var(--colors-gray-200);
}
`, "",{"version":3,"sources":["webpack://./../../packages/design_system_modules/forms/select/src/components/NoOptionsMessage.module.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;IAC1C,sCAAsC;IACtC,wCAAwC;AAC5C","sourcesContent":[".noOptionsMessage {\n    padding: calc(2 * var(--globals-baseUnit));\n    border-radius: var(--globals-baseUnit);\n    background-color: var(--colors-gray-200);\n}\n"],"sourceRoot":""}]);
// Exports
export var noOptionsMessage = `RHalcmrDHW4VJ5UA6pqM`;
export default ___CSS_LOADER_EXPORT___;
