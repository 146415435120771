import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const EditIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M6.899 1.421 7.319 1l1.682 1.681-.421.421-1.681-1.681Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m6.465 1.851.01.01.01-.01 1.682 1.682-4.933 4.933-.597.595H1V7.32L6.465 1.85Zm-4.87 6.615h.795l4.932-4.933-.848-.848-4.879 4.88v.901Z"
            />
        </SvgIcon>
    );
};
