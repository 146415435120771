import React, {ReactNode} from "react";
import {css} from "@emotion/react";

import {borderRadius, onDesktop} from "@pg-design/helpers-css";

interface IProps {
    children?: ReactNode;
}

export const ModalEmptyLayout = (props: IProps) => {
    const {children} = props;

    return <div css={innerWrap}>{children}</div>;
};

const innerWrap = () => css`
    position: relative;
    max-height: 100vh;
    height: 100%;
    max-width: 100vw;
    overflow: auto;
    display: flex;
    flex-direction: column;

    ${onDesktop(css`
        height: auto;
        ${borderRadius()};
    `)};
`;
