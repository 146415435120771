// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@layer packages {
    .oNiU1Bah7_YClmIZlOL0 {
        display: none;
    }

    .iR7Xy0jvhyxqlPndI7d2 {
        display: block;
    }

    @media (min-width: 1024px) {
        .oNiU1Bah7_YClmIZlOL0 {
            display: block;
        }

        .iR7Xy0jvhyxqlPndI7d2 {
            display: none;
        }
    }
}
`, "",{"version":3,"sources":["webpack://./../../packages/design_system_modules/core/grid/src/components/Hidden.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,cAAc;IAClB;;IAEA;QACI;YACI,cAAc;QAClB;;QAEA;YACI,aAAa;QACjB;IACJ;AACJ","sourcesContent":["@layer packages {\n    .hiddenMobile {\n        display: none;\n    }\n\n    .hiddenDesktop {\n        display: block;\n    }\n\n    @media (min-width: 1024px) {\n        .hiddenMobile {\n            display: block;\n        }\n\n        .hiddenDesktop {\n            display: none;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export var hiddenMobile = `oNiU1Bah7_YClmIZlOL0`;
export var hiddenDesktop = `iR7Xy0jvhyxqlPndI7d2`;
export default ___CSS_LOADER_EXPORT___;
