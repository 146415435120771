import React, {forwardRef} from "react";
import classNames from "classnames";

import {IFit} from "./Picture";

import {hasErrorModule, image, imageFit} from "./Image.module.css";

interface IImage extends React.HTMLAttributes<HTMLImageElement> {
    alt: string;
    fit?: IFit;
    hasError?: boolean;
    loading?: "lazy" | "eager";
    fetchPriority?: "high" | "low" | "auto";
    height: number;
    width: number;
}

export const Image = forwardRef<HTMLImageElement, IImage>((props, ref) => {
    const {fit, className, hasError, loading, alt, ...restProps} = props;

    const style = fit ? {"--object-position": fit} : {};

    const classes = classNames(image, fit && imageFit, hasError && hasErrorModule, className);

    return (
        <img alt={alt} loading={loading} ref={ref} fetchpriority={props.fetchPriority} onLoad={props.onLoad} className={classes} style={style} {...restProps} />
    );
});
