export enum StatusType {
    ALL = 0,
    RECOMMENDED = 1,
    APPLICATION_SENT = 2,
    FAVORITE = 3,
    DISMISSED = 4
}

export enum StatusLabel {
    RECOMMENDED = "Rekomendowane",
    APPLICATION_SENT = "Wysłano zapytanie",
    FAVORITE = "Ulubione",
    DISMISSED = "Odrzucone"
}
