import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const SettingsIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.683 4.198c.026.054.049.11.07.166.047.119.082.242.104.367a1.9 1.9 0 0 1 .022.274 1.953 1.953 0 0 1-.18.793 1.842 1.842 0 0 1-.2.325 1.882 1.882 0 0 1-1.495.75 1.91 1.91 0 0 1-.804-.184 1.869 1.869 0 0 1-.96-1.065 1.969 1.969 0 0 1-.106-.617 1.9 1.9 0 0 1 .748-1.495 1.962 1.962 0 0 1 .49-.264A1.96 1.96 0 0 1 5 3.138a1.9 1.9 0 0 1 1.11.368c.245.18.441.418.573.692Zm-.396 1.059c.006-.029.013-.058.013-.086a1.346 1.346 0 0 0-.002-.35l-.003-.02a1.308 1.308 0 0 0-.2-.532l-.04-.057-.01-.012a1.3 1.3 0 0 0-.245-.252l-.005-.003a1.32 1.32 0 0 0-.528-.25l-.015-.003c-.022-.005-.045-.01-.068-.01a1.372 1.372 0 0 0-.194-.017c-.061 0-.122.005-.182.014l-.025.004-.05.008c-.17.033-.33.1-.473.195-.017.01-.033.023-.05.035a1.319 1.319 0 0 0-.526.812l-.001.01a.342.342 0 0 0-.01.066 1.208 1.208 0 0 0 .006.398c.003.02.005.04.01.06a1.32 1.32 0 0 0 .253.533 1.285 1.285 0 0 0 .786.484l.017.004c.023.005.047.01.07.01a1.2 1.2 0 0 0 .352 0 1.318 1.318 0 0 0 .927-.579 1.317 1.317 0 0 0 .193-.462Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.993 4.2v1.591h-.005V5.8h-.619c-.087.36-.232.704-.428 1.018L8 6.877l.38.377-1.125 1.133-.377-.377-.06-.061A3.449 3.449 0 0 1 5.8 8.37V9H4.2v-.635a3.463 3.463 0 0 1-1.025-.424l-.091.092-.377.377L1.58 7.283l.377-.376.091-.091A3.424 3.424 0 0 1 1.626 5.8H1V4.2h.623a3.46 3.46 0 0 1 .422-1.026l-.457-.457L2.715 1.59l.38.377.079.079A3.444 3.444 0 0 1 4.2 1.619V1h1.6v.619c.36.086.702.229 1.016.424l.084-.084.381-.377 1.127 1.127-.377.377-.085.084c.197.318.34.666.425 1.03h.622ZM7.907 5.265h.552v-.534h-.551a2.92 2.92 0 0 0-.666-1.611l.385-.384.027-.026-.373-.373-.027.026-.385.385a2.89 2.89 0 0 0-1.605-.666v-.549H4.73v.549a2.91 2.91 0 0 0-1.608.669l-.407-.407-.373.373.407.407a2.921 2.921 0 0 0-.663 1.607h-.55v.536h.552a2.852 2.852 0 0 0 .357 1.15 3 3 0 0 0 .307.447l-.385.385-.033.034.373.373.033-.034.385-.387A2.918 2.918 0 0 0 4.73 7.9v.565h.537V7.9a2.907 2.907 0 0 0 1.598-.66l.386.386.373-.373-.385-.385a2.905 2.905 0 0 0 .668-1.603Z"
            />
        </SvgIcon>
    );
};
