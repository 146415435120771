import {useCallback, useState} from "react";

interface IProps {
    openedByDefault?: boolean;
    onAfterOpen?: () => void;
    onAfterClose?: () => void;
}

export const useModal = <TCustomParams = {}>(props: IProps = {}) => {
    const [shouldModalBeOpened, setModalShouldBeOpened] = useState(props.openedByDefault || false);
    const [isModalRendered, setIsModalRendered] = useState(props.openedByDefault || false);
    const [customParams, setCustomParams] = useState<TCustomParams>();

    const closeModal = useCallback(() => {
        setModalShouldBeOpened(false);
    }, [setModalShouldBeOpened]);

    const openModal = useCallback(
        (customParams?: TCustomParams) => {
            setCustomParams(customParams);
            setModalShouldBeOpened(true);
        },
        [setModalShouldBeOpened]
    );

    const onAfterOpen = useCallback(() => {
        setIsModalRendered(true);

        if (props.onAfterOpen) {
            props.onAfterOpen();
        }
    }, [setIsModalRendered]);

    const onAfterClose = useCallback(() => {
        setIsModalRendered(false);

        if (props.onAfterClose) {
            props.onAfterClose();
        }
    }, [setIsModalRendered]);

    return {
        shouldModalBeOpened,
        isModalRendered,
        closeModal,
        openModal,
        modalProps: {
            onModalClose: closeModal,
            isOpen: shouldModalBeOpened,
            customParams,
            onAfterOpen,
            onAfterClose
        }
    };
};
