// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UmkaRKnoO00FckmPdhwA {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: var(--globals-baseUnit);
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(174, 177, 180, 0.3);
}

.xLNdafGtpjCTM00j1AZQ {
    padding: var(--globals-baseUnit) calc(2 * var(--globals-baseUnit));
    cursor: pointer;
    border-bottom: 1px solid #f2f2f2;
}

.xLNdafGtpjCTM00j1AZQ:hover {
    background: #f2f2f2;
}
`, "",{"version":3,"sources":["webpack://./../../packages/design_system_modules/forms/inputs/src/components/input_range/InputRangeOptions.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,sCAAsC;IACtC,gBAAgB;IAChB,+CAA+C;AACnD;;AAEA;IACI,kEAAkE;IAClE,eAAe;IACf,gCAAgC;AACpC;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".optionWrapperStyle {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    border-radius: var(--globals-baseUnit);\n    overflow: hidden;\n    box-shadow: 0 4px 10px rgba(174, 177, 180, 0.3);\n}\n\n.optionElementStyle {\n    padding: var(--globals-baseUnit) calc(2 * var(--globals-baseUnit));\n    cursor: pointer;\n    border-bottom: 1px solid #f2f2f2;\n}\n\n.optionElementStyle:hover {\n    background: #f2f2f2;\n}\n"],"sourceRoot":""}]);
// Exports
export var optionWrapperStyle = `UmkaRKnoO00FckmPdhwA`;
export var optionElementStyle = `xLNdafGtpjCTM00j1AZQ`;
export default ___CSS_LOADER_EXPORT___;
