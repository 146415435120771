import {prefixPath} from "@pg-mono/prefix-path";

import {createLink} from "./utils/create_link";
import {createNoParamLink} from "./utils/create_no_param_link";
import {V1_API_PREFIX} from "./api_prefixes";

export const mpApiPaths = prefixPath(V1_API_PREFIX, {
    applications: prefixPath("applications/", {
        modularHouse: prefixPath("modular-house-application/", {
            list: "",
            detail: ":applicationId"
        })
    }),
    modularHouses: prefixPath("modular-houses/", {
        base: "",
        constructionOption: prefixPath("construction-option/", {
            base: ""
        }),
        contractor: prefixPath("contractor/", {
            base: "",
            detail: prefixPath(":contractorId(\\d+)/", {
                base: ""
            })
        }),
        offer: prefixPath("offer/", {
            base: "",
            detail: prefixPath(":offerId(\\d+)/", {
                base: ""
            })
        }),
        gallery: prefixPath("gallery/", {
            base: "",
            detail: ":galleryId(\\d+)/"
        })
    }),
    stats: prefixPath("stats/", {
        constractor: prefixPath("contractor/", {
            detail: prefixPath(":contractorId(\\d+)/", {
                applications: "applications/"
            })
        })
    })
});

export const mpApiLinks = {
    applications: {
        modularHouse: {
            list: createNoParamLink(mpApiPaths.applications.modularHouse.list),
            detail: createLink<{applicationId: string}>(mpApiPaths.applications.modularHouse.detail)
        }
    },
    modularHouses: {
        constructionOption: {
            base: createNoParamLink(mpApiPaths.modularHouses.constructionOption.base)
        },
        contractor: {
            base: createNoParamLink(mpApiPaths.modularHouses.contractor.base),
            detail: {
                base: createLink<{contractorId: number}>(mpApiPaths.modularHouses.contractor.detail.base)
            }
        },
        offer: {
            base: createNoParamLink(mpApiPaths.modularHouses.offer.base),
            detail: createLink<{offerId: number}>(mpApiPaths.modularHouses.offer.detail.base)
        },
        gallery: {
            base: createNoParamLink(mpApiPaths.modularHouses.gallery.base),
            detail: createLink<{galleryId: number}>(mpApiPaths.modularHouses.gallery.detail)
        }
    },
    stats: {
        contractor: {
            applications: createLink<{contractorId: number}>(mpApiPaths.stats.constractor.detail.applications)
        }
    }
};
