import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const CalendarCheckIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M7.4 1.533V1h-.533v.533H3.133V1H2.6v.533H1V9h8V1.533H7.4Zm1.067 6.934H1.533v-4.8h6.934v4.8Zm0-5.334H1.533V2.067H2.6V2.6h.533v-.533h3.734V2.6H7.4v-.533h1.067v1.066Z" />
            <path d="m4.09 7.133.377.378.377-.378 2.262-2.262-.377-.377-2.262 2.262-1.132-1.13-.377.376L4.09 7.133Z" />
        </SvgIcon>
    );
};
