import type {Middleware, MiddlewareAPI} from "@reduxjs/toolkit";
import {isRejected, isRejectedWithValue} from "@reduxjs/toolkit";

import {notifyBugsnagClient} from "@pg-mono/bugsnag-client";
import {consoleError} from "@pg-mono/logger";

export const rtkQueryErrorHandlerMiddleware: Middleware = (_: MiddlewareAPI) => (next) => (action) => {
    // `condition: true` means that action was rejected due to the cached value in fact it is not an error
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (action?.meta?.condition) {
        return next(action);
    }

    if (isRejected(action) || isRejectedWithValue(action)) {
        const error = new Error(`Error in redux action type: ${action.type}`);

        consoleError(error.message);
        /*
         * For now we are checking if user is logged in by fetching his profile data from API.
         * 401 status means user is not logged in, and it is not an error for us (just log it).
         * In the future BE should expose dedicated endpoint for checking user logged state.
         * It should return {is_authenticated: boolean}.
         */
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (action.payload.status === 401) {
            return next(action);
        }

        notifyBugsnagClient(error, "", action);
    }

    return next(action);
};
