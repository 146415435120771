import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {Redirect, Route, Switch, useLocation} from "react-router";

import {minHeight} from "@pg-design/helpers-css";
import {Loader} from "@pg-design/loader";
import {kmAppPath} from "@pg-mono/km-routes";
import {parseSearch} from "@pg-mono/request";

import {CenterBox} from "../../components/CenterBox";
import {setRedirectPath} from "../../modules/auth/state/auth_slice";
import {CplUnsubscribeView} from "../../modules/mailing/views/CplUnsubscribeView";
import {MailingUnsubscribeView} from "../../modules/mailing/views/MailingUnsubscribeView";
import {useGetUserProfileQuery} from "../../modules/user/api/user_api";
import {fetchVariables} from "../api/fetch_variables";
import {AuthenticatedLayout} from "../layouts/AuthenticatedLayout";
import {setVariables} from "../state/variables_slice";

const UnauthenticatedRoutes = React.lazy(() => import("./UnauthenticatedRoutes"));
const AuthenticatedRoutes = React.lazy(() => import("./AuthenticatedRoutes"));

export const AuthenticationRouting = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const {data, isLoading, isUninitialized} = useGetUserProfileQuery();
    const profileFetchFinished = !isUninitialized && !isLoading;

    useEffect(() => {
        const {next: redirectPath} = parseSearch(location.search);

        if (redirectPath && typeof redirectPath === "string") {
            dispatch(setRedirectPath(redirectPath));
        }
    });

    useEffect(() => {
        fetchVariables().then((res) => dispatch(setVariables(res.data)));
    }, []);

    const loader = (
        <CenterBox css={minHeight("100vh")}>
            <Loader size="lg" />
        </CenterBox>
    );

    if (isLoading) {
        return loader;
    }

    return (
        <Switch>
            <Route path="/:url*" exact strict render={(props) => <Redirect to={`${props.location.pathname}/`} />} />
            {/* Routes for both authenticated and unauthenticated users */}
            <Route path={kmAppPath.site.unsubscribe.cpl} exact component={CplUnsubscribeView} />
            {profileFetchFinished && <Route path={kmAppPath.site.unsubscribe.mailing} exact component={() => <MailingUnsubscribeView userProfile={data} />} />}

            <React.Suspense fallback={loader}>
                {data ? (
                    <AuthenticatedLayout>
                        <AuthenticatedRoutes />
                    </AuthenticatedLayout>
                ) : (
                    <UnauthenticatedRoutes />
                )}
            </React.Suspense>
        </Switch>
    );
};
