import {ForwardedRef, forwardRef, ReactNode} from "react";

import {IFieldState} from "../types";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    fieldState: IFieldState;
    children?: ReactNode;
}

export const TextInputBase = forwardRef((props: Props, forwardedRef: ForwardedRef<HTMLDivElement>) => {
    return (
        <div ref={forwardedRef} style={{cursor: props.fieldState === "disabled" ? "inherit" : "text"}}>
            {props.children}
        </div>
    );
});
