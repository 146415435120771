import {pluralize} from "@pg-mono/string-utils";

import {OfferType} from "../types/OfferType";

export const getOfferTypeName = (offerType: OfferType) => {
    switch (offerType) {
        case OfferType.FLAT:
            return "mieszkanie";
        case OfferType.HOUSE:
            return "dom";
        case OfferType.COMMERCIAL:
            return "lokal użytkowy";
        default:
            throw new Error("Unknown offer type");
    }
};

export const getOfferTypeNameCapital = (offerType: OfferType) => {
    switch (offerType) {
        case OfferType.FLAT:
            return "Mieszkanie";
        case OfferType.HOUSE:
            return "Dom";
        case OfferType.COMMERCIAL:
            return "Lokal użytkowy";
        default:
            throw new Error("Unknown offer type");
    }
};

export const getOfferTypePluralize = (amount: number, offerType: number, isApartment = false) => {
    switch (offerType) {
        case OfferType.FLAT: {
            const flatPluralize = pluralize(isApartment ? ["apartament", "apartamenty", "apartamentów"] : ["mieszkanie", "mieszkania", "mieszkań"]);
            return flatPluralize(amount);
        }
        case OfferType.HOUSE: {
            const housePluralize = pluralize(["dom", "domy", "domów"]);
            return housePluralize(amount);
        }
        case OfferType.COMMERCIAL: {
            const commercialPluralize = pluralize(["lokal", "lokale", "lokali"]);
            return commercialPluralize(amount);
        }
        default: {
            break;
        }
    }
};

export const getNewOfferTypeNameCapital = (type: OfferType) => {
    switch (type) {
        case OfferType.FLAT: {
            return "Nowe mieszkanie";
        }
        case OfferType.HOUSE: {
            return "Nowy dom";
        }
        case OfferType.COMMERCIAL: {
            return "Nowy lokal użytkowy";
        }
        default: {
            return "Nowa nieruchomość";
        }
    }
};
