import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import {kmApiLink} from "@pg-mono/km-routes";

import {getFetchHeaders} from "../../../app/utils/get_fetch_headers";
import {IUserProfile} from "../interfaces/IUserProfile";
import {MarketingConsentType} from "../views/UserSubscriptionsView";

export const userApi = createApi({
    reducerPath: "user",
    baseQuery: fetchBaseQuery({
        baseUrl: "/"
    }),
    tagTypes: ["Preferences", "User"],
    endpoints: (builder) => ({
        getUserPreferences: builder.query<Record<MarketingConsentType, boolean>, void>({
            query: () => {
                return {
                    url: kmApiLink.user.preferences()
                };
            },
            providesTags: ["Preferences"]
        }),
        getUserProfile: builder.query<IUserProfile, void>({
            query: () => {
                return {
                    url: kmApiLink.user.info()
                };
            },
            providesTags: ["User"]
        }),
        updateUserPreferences: builder.mutation<Record<MarketingConsentType, boolean>, Record<MarketingConsentType, boolean> | {update_source: string}>({
            query: (request) => {
                return {
                    url: kmApiLink.user.preferences(),
                    method: "PUT",
                    body: request,
                    headers: getFetchHeaders()
                };
            },
            invalidatesTags: ["Preferences"]
        }),
        changeUserPassword: builder.mutation<{email: string}, {previous_password: string; new_password: string}>({
            query: (request) => {
                return {
                    url: kmApiLink.user.changePassword(),
                    method: "PUT",
                    body: request,
                    headers: getFetchHeaders()
                };
            }
        }),
        updateAccountDetails: builder.mutation<IUserProfile, {name: string; email: string; phone: string}>({
            query: (request) => {
                return {
                    url: kmApiLink.user.info(),
                    method: "PUT",
                    body: request,
                    headers: getFetchHeaders()
                };
            },
            invalidatesTags: ["User"]
        })
    })
});

export const selectUserProfile = userApi.endpoints.getUserProfile.select();

export const {
    useChangeUserPasswordMutation,
    useGetUserPreferencesQuery,
    useGetUserProfileQuery,
    useUpdateUserPreferencesMutation,
    useUpdateAccountDetailsMutation
} = userApi;
