import {forwardRef, MouseEvent, useRef, useState} from "react";

import {PlusIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";
import {useCombinedRefs} from "@pg-mono/hooks";

import {TextInputBase} from "../../../atoms/TextInputBase";
import {IInputWrapperProps, InputWrapper} from "../../../atoms/wrapper/InputWrapper";
import {IFieldState} from "../../../types";
import {IInputCoreProps, InputCore} from "../../input/InputCore";

import {fileNameStyle, inputFileStyle, inputWrapperStyle, placeholder, rightElementStyle, selectFileInfo, text} from "./FileInput.module.css";

export type IFileInputProps = IInputWrapperProps &
    IInputCoreProps & {
        fileName?: string;
    };
type IFileInputOwnProps = IFileInputProps;

export const FileInput = forwardRef<HTMLInputElement, IFileInputOwnProps>((props, forwardedRef) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {fieldState, fileName, detail, onFocus, onBlur, className, value, ...inputCoreProps} = props;
    const localRef = useRef<HTMLInputElement>(null);
    const inputRef = useCombinedRefs<HTMLInputElement>(forwardedRef, localRef);

    const [isFocused, setFocused] = useState(false);

    const events = {
        onFocus: () => {
            setFocused(true);
        },
        onBlur: () => {
            setFocused(false);
        },
        onClick: (event: MouseEvent) => {
            event.stopPropagation();

            if (localRef.current) {
                localRef.current.focus();
            }
        }
    };

    const innerFieldState: IFieldState = isFocused ? "focused" : fieldState ? fieldState : "default";

    return (
        <TextInputBase onClick={events.onClick} fieldState={innerFieldState} className={className}>
            <InputWrapper
                fieldState={innerFieldState}
                detail={detail}
                className={inputWrapperStyle}
                elementCustomClassName={rightElementStyle}
                rightElement={
                    <div className={selectFileInfo}>
                        <PlusIcon size="2.5" />
                        <Text variant="button_small" className={text}>
                            Wybierz plik
                        </Text>
                    </div>
                }
            >
                <div className={placeholder}>
                    <div className={fileNameStyle}>{fileName || props.placeholder}</div>
                </div>
                <InputCore
                    {...inputCoreProps}
                    ref={inputRef}
                    onFocus={(event) => {
                        events.onFocus();
                        onFocus && onFocus(event);
                    }}
                    onBlur={(event) => {
                        events.onBlur();
                        onBlur && onBlur(event);
                    }}
                    className={inputFileStyle}
                    type="file"
                />
            </InputWrapper>
        </TextInputBase>
    );
});
