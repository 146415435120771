import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const BrandWallPaintRollerIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M9.98888 8.61088C9.68829 9.08577 8.66392 10.0954 8.53172 9.99271C7.8781 9.48459 6.02433 7.6382 6.02433 7.6382C5.6558 7.28074 6.17426 6.8871 6.41355 6.6478C6.36111 6.53628 5.77027 6.14633 5.87441 5.98828L7.77175 1.64042C7.87884 1.52816 6.58859 0.360507 6.52064 0.294037C6.18682 -0.0316644 5.63586 1.0998 5.45639 0.952088C5.16318 0.709842 6.25402 -0.143186 6.49848 0.0207728C6.79095 0.216489 7.35299 0.667006 7.97707 1.40113C8.22079 1.68769 6.17648 5.73643 6.17721 6.01191L6.61591 6.44396C6.85373 6.20246 7.25255 5.68547 7.60779 6.05327C7.67426 6.18326 10.1824 8.45947 9.98815 8.61014" />
            <path d="M6.61599 2.08879C6.71939 2.27195 1.92397 7.06072 1.74376 6.96102C1.50447 7.05925 -0.0952369 5.22763 0.00446774 5.04743C0.188367 4.71656 4.28216 0.543735 4.48379 0.351712C5.03106 -0.170445 6.53844 1.80149 6.61599 2.08879ZM0.179505 5.10799C0.169165 5.18037 1.67507 6.68111 1.74376 6.67298C1.84789 6.55629 6.45572 2.24093 6.29767 2.01272C6.22456 1.98022 4.8634 0.497945 4.7637 0.523794C4.6315 0.570323 0.224556 4.97653 0.178766 5.10873" />
        </SvgIcon>
    );
};
