import {css} from "@emotion/react";

const buttonReset = css`
    button {
        border: none;
        padding: 0;
        background-color: transparent;
        cursor: pointer;
    }
`;

export const resetStyles = css`
    ${buttonReset}
`;
