import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const FloorIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M9 2H6.539v1.846H4.692v1.846H2.846v1.846H1v.616H3.461V6.308h1.847V4.46h1.846V2.615H9V2Z" />
        </SvgIcon>
    );
};
