import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const TrashIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M6.3 1H3.633v.533H6.3V1ZM3.9 4.203h.533v3.194H3.9V4.203ZM5.5 4.203h.533v3.194H5.5V4.203Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.5 3.137V1.538h6.933v1.595H7.9V9H2.033V3.137H1.5Zm1.067 5.33h4.8V3.133h-4.8v5.334ZM2.033 2.6H7.9v-.53H2.033v.53Z"
            />
        </SvgIcon>
    );
};
