import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

interface IProps extends IIcon {
    secondaryFill?: string;
}

export const LikeFillIcon = (props: IProps) => {
    return (
        <SvgIcon {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.333 2.33v1.337H9V4.2H5.8V2.055a.533.533 0 0 0-.534-.533v1.066l-.038.088-.184.424L4.222 5h-.555v2.933h4.8V7.4h-1.29v-.533h1.29v-.534h-1.29V5.8h1.29v-.533h-1.29v-.534h1.29V4.2H9v4.267H1V4.2h2.673v.267h.2l.859-1.989V1.163c.16-.101.345-.157.534-.163H5.3a1.064 1.064 0 0 1 1.033 1.33Zm-4.8 5.603h1.6v-3.2h-1.6v3.2Z"
            />
            <path d="M3.09951 4.78533H1.49951V7.97254H3.09951V4.78533Z" fill={props.secondaryFill || "var(--colors-primary)"} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.50117 4.78465H7.20117V5.28265H8.50117V5.78065H7.20117V6.27865H8.50117V6.77665H7.20117V7.27465H8.50117V7.77265H3.70117V4.98385H4.20117L5.00117 3.19105L5.20117 2.69305V1.59745C5.30117 1.59745 5.30117 1.59745 5.40117 1.59745C5.50117 1.59745 5.50117 1.69705 5.60117 1.69705C5.60117 1.69705 5.70117 1.79665 5.70117 1.89625C5.70117 1.99585 5.70117 1.99585 5.70117 2.09545V4.18705H8.40117V4.78465H8.50117Z"
                fill={props.secondaryFill || "var(--colors-primary)"}
            />
        </SvgIcon>
    );
};
