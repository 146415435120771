import {initAlgolytics} from "@pg-mono/algolytics";
import {GoogleMapsApiConfig} from "@pg-mono/google-api";
import {setDefaultOptions as setOSMDefaultOptions} from "@pg-mono/open-street-map";
import {initUserCom} from "@pg-mono/user-com";

import {GOOGLE_API_KEY} from "../../common/app/constants/keys";
import {algolyticsId, osmTileUrl, showAlgolyticsLogs, userComAppSubdomain, userComId} from "../../common/app/utils/read_environment_variables";
import {initGoogleMap} from "../../common/modules/map/utils/init_google_map";
import {initGoogleTagManager} from "../../common/modules/tracking/gtm/init_gtm";

export const initClientApp = () => (renderApp: () => void) => {
    initGoogleMap();

    if (osmTileUrl) {
        setOSMDefaultOptions({
            tileUrl: osmTileUrl,
            tileSize: 256,
            tileCrossOrigin: false,
            defaultMinZoom: 7
        });
    }

    GoogleMapsApiConfig.setApiKey(GOOGLE_API_KEY);

    initUserCom({userComId, userComAppSubdomain});
    initAlgolytics(algolyticsId, showAlgolyticsLogs, "www_events_km");
    initGoogleTagManager();

    renderApp();
};
