import {AxiosError} from "axios";

import {notifyBugsnagClient} from "@pg-mono/bugsnag-client";
import {consoleError} from "@pg-mono/logger";

import {addNotification} from "../../modules/notifications/state/notifications_slice";
import {store} from "../store/store";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchErrorHandler = (error: AxiosError<any>) => {
    if (error.response?.data?.non_field_errors) {
        error.response.data.non_field_errors.map((value: string) => {
            store.dispatch(addNotification({type: "failure", content: value}));
        });
    }

    consoleError(error.message);

    notifyBugsnagClient(error, error.message);
};
