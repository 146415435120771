export enum NavigationLinkType {
    MY_OFFERS,
    PREFERENCES,
    MARKET_DATA,
    MY_ADS,
    SETTINGS,
    RECOMMENDED,
    APPLICATION_SENT,
    FAVORITE,
    DISMISSED
}
