// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MEQd5GVJwxvQlXix8yFN {
    --field-state-color: var(--colors-gray-600);

    color: var(--field-state-color);
    text-align: right;
    margin-top: calc(0.5 * var(--globals-baseUnit));
}
`, "",{"version":3,"sources":["webpack://./../../packages/design_system_modules/forms/inputs/src/atoms/FieldMessage.module.css"],"names":[],"mappings":"AAAA;IACI,2CAA2C;;IAE3C,+BAA+B;IAC/B,iBAAiB;IACjB,+CAA+C;AACnD","sourcesContent":[".fieldMessageWrapper {\n    --field-state-color: var(--colors-gray-600);\n\n    color: var(--field-state-color);\n    text-align: right;\n    margin-top: calc(0.5 * var(--globals-baseUnit));\n}\n"],"sourceRoot":""}]);
// Exports
export var fieldMessageWrapper = `MEQd5GVJwxvQlXix8yFN`;
export default ___CSS_LOADER_EXPORT___;
