import {getCookie} from "@pg-mono/request";

export const getFetchHeaders = (): Record<string, string> => {
    const csrfToken = getCookie("csrftoken");

    return csrfToken == null
        ? {}
        : {
              "X-CSRFToken": csrfToken
          };
};
