import * as pathToRegexp from "path-to-regexp";

import {appendQueryString} from "@pg-mono/request";

export function createLinkWithScenario<TScenario, TParams>(apiPathElement: string) {
    type ParamsType = TParams | null;
    const compiler = pathToRegexp.compile(apiPathElement);
    return (scenario: TScenario, apiPathParams: ParamsType = null): string =>
        appendQueryString(compiler(apiPathParams as unknown as Record<string, string>), {s: scenario as unknown as string});
}
