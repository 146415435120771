export enum KitchenType {
    CLOSE = 1,
    OPEN = 2
}

export const getKitchenTypeNameWith = (type: KitchenType) => {
    switch (type) {
        case KitchenType.CLOSE:
            return "z kuchnią";
        case KitchenType.OPEN:
            return "z aneksem";
        default:
            return "";
    }
};
export const getKitchenTypeName = (type: KitchenType) => {
    switch (type) {
        case KitchenType.CLOSE:
            return "kuchnia";
        case KitchenType.OPEN:
            return "aneks";
        default:
            return "";
    }
};
