import {useEffect, useState} from "react";
import {useRouteMatch} from "react-router";

import {IThemeGH, IThemeKM, IThemeRP, themeGH, themeKM, themeRP} from "@pg-design/themes";
import {kmAppPath, SiteSlug} from "@pg-mono/km-routes";

export type ITheme = IThemeGH | IThemeKM | IThemeRP;

export const useMatchedTheme = (): ITheme => {
    const matchedSite = useRouteMatch<{site: string}>(kmAppPath.site.base)?.params.site;

    const [theme, setTheme] = useState<ITheme>(themeKM);

    useEffect(() => {
        setTheme(getMatchedTheme(matchedSite));
    }, [matchedSite]);

    return theme;
};

const getMatchedTheme = (site?: string): ITheme => {
    switch (site) {
        case SiteSlug.GH:
            return themeGH;
        case SiteSlug.RP:
            return themeRP;
        default:
            return themeKM;
    }
};
