import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import {LatLngLiteral} from "@pg-mono/open-street-map";
import {IOsmGeoAssetsPoi, IOsmPoisRoutes, IOSMPoisRoutesGrouped, IOsmPoisRoutesParams, IPublicTransportRouteWithStops} from "@pg-mono/osm-with-poi";

export const DEFAULT_RADIUS = 3000;

interface IGetDataWithId {
    id: number;
}

export const openStreetMapApi = createApi({
    reducerPath: "map",
    baseQuery: fetchBaseQuery({
        baseUrl: "https://api.ebbig.pl/"
    }),
    endpoints: (builder) => ({
        getMapPoi: builder.query<IOsmGeoAssetsPoi[], LatLngLiteral>({
            query: (coordinates: LatLngLiteral) => {
                return {
                    url: `/geo-assets/poi/`,
                    params: {
                        point: `${coordinates.lat}, ${coordinates.lng}`,
                        dist: DEFAULT_RADIUS
                    }
                };
            }
        }),
        getPublicTransportRoute: builder.query<IPublicTransportRouteWithStops, IGetDataWithId>({
            query: (params: IGetDataWithId) => {
                return {
                    url: `/geo-assets/poi/${params.id}/route-geometry/`
                };
            }
        }),
        getOsmPoisRoutesGrouped: builder.query<IOSMPoisRoutesGrouped, IOsmPoisRoutesParams>({
            query: (params: IOsmPoisRoutesParams) => {
                return {
                    url: `/geo-assets/poi/routes/`,
                    params: {
                        point: `${params.latitude}, ${params.longitude}`,
                        dist: params.radius || DEFAULT_RADIUS
                    }
                };
            }
        }),
        getOsmPoisRoutes: builder.query<IOsmPoisRoutes, IGetDataWithId>({
            query: (params: IGetDataWithId) => {
                return {
                    url: `/geo-assets/poi/${params.id}/routes/`
                };
            }
        })
    })
});

export const {useGetMapPoiQuery, useGetPublicTransportRouteQuery, useGetOsmPoisRoutesGroupedQuery, useGetOsmPoisRoutesQuery} = openStreetMapApi;
