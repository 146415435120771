// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xYMjUkUPDJ9eIbHPdOvx {
    position: relative;
    padding: calc(var(--globals-baseUnit) * 1.5) calc(var(--globals-baseUnit) * 1.5) calc(var(--globals-baseUnit) * 1.5) calc(var(--globals-baseUnit) * 2);
}

.AZm0dAewHgAXdBDs13w6 {
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./../../packages/design_system_modules/forms/inputs/src/components/fields/date_field/DateInput.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,sJAAsJ;AAC1J;;AAEA;IACI,WAAW;AACf","sourcesContent":[".inputWrapperStyle {\n    position: relative;\n    padding: calc(var(--globals-baseUnit) * 1.5) calc(var(--globals-baseUnit) * 1.5) calc(var(--globals-baseUnit) * 1.5) calc(var(--globals-baseUnit) * 2);\n}\n\n.inputCoreStyle {\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export var inputWrapperStyle = `xYMjUkUPDJ9eIbHPdOvx`;
export var inputCoreStyle = `AZm0dAewHgAXdBDs13w6`;
export default ___CSS_LOADER_EXPORT___;
