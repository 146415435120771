import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const KitchenIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M9 4.733H1V9h8V4.733Zm-.533.534v3.2H7.4v-3.2h1.067ZM3.133 7.4h3.734v1.067H3.133V7.4Zm3.734-2.133v1.6H3.133v-1.6h3.734Zm-4.267 0v3.2H1.533v-3.2H2.6ZM7.4 3.933V3.4L5.8 1.8V1H4.2v.8L2.6 3.4v.533h4.8Zm-2.133-2.4v.488L6.646 3.4H3.354l1.379-1.379v-.488h.534Z" />
            <path d="M5 6.333a.267.267 0 1 0 0-.534.267.267 0 0 0 0 .534ZM3.933 6.333a.267.267 0 1 0 .002-.534.267.267 0 0 0-.002.534ZM6.067 6.333a.267.267 0 1 0 0-.534.267.267 0 0 0 0 .534Z" />
        </SvgIcon>
    );
};
