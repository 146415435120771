// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@layer packages {
    .D3uHpjDBFdKXrogLoniA {
        display: flex;
        position: relative;
        overflow: hidden;
    }

    .DpD4UTqxLO0BN069ENc8 {
        position: absolute;
        z-index: 10;
        top: calc(2 * var(--globals-baseUnit));
        right: calc(2 * var(--globals-baseUnit));
        display: flex;
        column-gap: calc(2 * var(--globals-baseUnit));
    }

    .VEe2R4gDN0SScY1Li35J {
        cursor: pointer;
    }

    .TzQvjIKUQ4iYx0qe4o4B {
        flex: 1 1 auto;
    }

    .DZJVO63fD4cf4pCdxKq7 {
        width: 100%;
    }
}
`, "",{"version":3,"sources":["webpack://./../../packages/design_system_modules/forms/inputs/src/components/fields/image_field/ImageFieldPreview.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,aAAa;QACb,kBAAkB;QAClB,gBAAgB;IACpB;;IAEA;QACI,kBAAkB;QAClB,WAAW;QACX,sCAAsC;QACtC,wCAAwC;QACxC,aAAa;QACb,6CAA6C;IACjD;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,cAAc;IAClB;;IAEA;QACI,WAAW;IACf;AACJ","sourcesContent":["@layer packages {\n    .imageWrap {\n        display: flex;\n        position: relative;\n        overflow: hidden;\n    }\n\n    .iconsWrap {\n        position: absolute;\n        z-index: 10;\n        top: calc(2 * var(--globals-baseUnit));\n        right: calc(2 * var(--globals-baseUnit));\n        display: flex;\n        column-gap: calc(2 * var(--globals-baseUnit));\n    }\n\n    .pointer {\n        cursor: pointer;\n    }\n\n    .imageHolder {\n        flex: 1 1 auto;\n    }\n\n    .imageStyle {\n        width: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export var imageWrap = `D3uHpjDBFdKXrogLoniA`;
export var iconsWrap = `DpD4UTqxLO0BN069ENc8`;
export var pointer = `VEe2R4gDN0SScY1Li35J`;
export var imageHolder = `TzQvjIKUQ4iYx0qe4o4B`;
export var imageStyle = `DZJVO63fD4cf4pCdxKq7`;
export default ___CSS_LOADER_EXPORT___;
