import React from "react";
import {Provider} from "react-redux";
import createCache from "@emotion/cache";
import {CacheProvider, Global, ThemeProvider} from "@emotion/react";
import {GoogleOAuthProvider} from "@react-oauth/google";

import {DSThemeProvider, IThemeGH, IThemeKM, IThemeRP} from "@pg-design/themes";

import {useMatchedTheme} from "../hooks/use_matched_theme";
import {GOOGLE_OAUTH_CLIENT_ID} from "../modules/auth/utils/social_auth_config";
import {Notifications} from "../modules/notifications/components/Notifications";
import {AuthenticationRouting} from "./routes/AuthenticationRouting";
import {store} from "./store/store";
import {getGlobalStyles} from "./styles/global_styles";

const emotionCache = createCache({key: "km"});

declare module "@emotion/react" {
    export interface Theme extends IThemeGH, IThemeKM, IThemeRP {}
}

export const App = () => {
    const theme = useMatchedTheme();

    return (
        <Provider store={store}>
            <CacheProvider value={emotionCache}>
                <DSThemeProvider>
                    <ThemeProvider theme={theme}>
                        <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
                            <Global styles={getGlobalStyles(theme)} />

                            <Notifications />

                            <AuthenticationRouting />
                        </GoogleOAuthProvider>
                    </ThemeProvider>
                </DSThemeProvider>
            </CacheProvider>
        </Provider>
    );
};
