import {IFieldState} from "../types";

export const getFieldStateColor = (fieldState?: IFieldState) => {
    switch (fieldState) {
        case "error":
            return "--colors-danger";

        case "focused":
            return "--colors-info";

        default:
            return "--colors-gray-600";
    }
};
