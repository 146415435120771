import {pluralize} from "@pg-mono/string-utils";

export const getRoomsPlural = (amount: number) => {
    if (!amount) {
        return "";
    }

    const flatPluralize = pluralize(["pokój", "pokoje", "pokoi"]);
    return flatPluralize(amount);
};
