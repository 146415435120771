import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const BrandMobileTextIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M5.89 3.41H2.73c-.12 0-.12.13 0 .13h3.16c.1 0 .1-.12 0-.12Z" fill="#23232d" strokeWidth="0" />
            <path
                d="M4.63 3.56H2.72c-.07 0-.1-.04-.1-.08s.04-.08.1-.08H5.9c.05 0 .07.04.07.07 0 .03-.02.07-.07.08H4.62Zm.04-.15H2.72c-.05 0-.07.03-.07.05s.02.05.07.05h3.16s.06-.02.06-.04-.02-.04-.06-.04H4.67ZM5.9 4.25H3.21c-.1 0-.1.13 0 .13H5.9c.09 0 .09-.12 0-.12Z"
                strokeWidth="0"
            />
            <path
                d="M4.83 4.4H3.21c-.06 0-.09-.04-.09-.08s.03-.08.09-.08H5.91c.04 0 .06.04.06.07 0 .03-.02.07-.06.08H4.82Zm.04-.15H3.21c-.04 0-.06.02-.06.05s.02.05.06.05H5.9s.05-.02.05-.05c0-.02-.01-.04-.05-.04H4.87ZM5.89 5.09H2.73c-.12 0-.12.13 0 .13h3.16c.1 0 .1-.12 0-.12Z"
                strokeWidth="0"
            />
            <path
                d="M4.63 5.24H2.72c-.07 0-.1-.04-.1-.08s.04-.08.1-.08H5.9c.05 0 .07.04.07.07 0 .03-.02.07-.07.08H4.62Zm.04-.15H2.72c-.05 0-.07.03-.07.05s.02.05.07.05h3.16s.06-.02.06-.04-.02-.04-.06-.04H4.67Z"
                strokeWidth="0"
            />
            <path d="M1.66 2.27h5.29v4.8H1.66z" fill="none" strokeWidth="0" />
            <path d="M1.66 2.27h5.29v4.8H1.66z" fill="none" strokeWidth="0" />
            <path
                d="M8.34 7.02V.09H6.67L5.47.07H4.17c-.08 0-.15.01-.21.04s-.1.07-.13.13c-.05.1-.07.21-.07.32v1.69H1.75a.1.1 0 0 0-.1.1v3.96c0 .06.05.1.1.1H3.76v.61l.02 1.47V9.9l2.03-.01H8.3c.01 0 .04 0 .04-.04V6.99Zm-4.57-.79h-1.9V2.48h4.88v3.75c-.32-.01-.65-.07-.87.21-.09.12-.2.37-.32.34-.12-.03-.2-.39-.32-.48-.13-.1-.32-.06-.47-.06h-1Zm.3-4.47v-.8h3.96v7.88H4.09V6.43h.44c.12 0 .39-.04.5 0 .15.05.2.2.28.32.11.17.2.4.42.26.12-.08.18-.28.29-.39.23-.25.52-.19.85-.19.03 0 .06-.01.07-.03a.09.09 0 0 0 .03-.07V2.37a.1.1 0 0 0-.1-.1H4.1v-.5Zm4.15 8.03H3.92V6.43H4v2.44c0 .04.03.04.05.04H8.1V.92c0-.03 0-.04-.01-.06-.01-.01-.03-.01-.06-.01H4.09c-.05 0-.06.02-.07.06-.01.07-.01.14-.01.21v1.1h-.1V.43c0-.07.03-.14.05-.19 0-.02.02-.03.04-.04.03-.01.05-.02.07-.03h.01s.07-.02.1-.02h4.01v9.58Z"
                strokeWidth="0"
            />
            <path
                d="M6.26 9.13c-.05-.03-.11-.06-.18-.05-.03 0-.06 0-.09.02-.04.02-.07.06-.09.09-.06.07-.06.15-.03.23.05.1.14.16.24.19.03.01.07 0 .1 0 .04-.03.08-.06.11-.1.04-.07.06-.15.03-.23-.02-.05-.04-.11-.09-.14M5.53.6h1c.02 0 .02-.02.02-.03 0-.02-.01-.03-.03-.03h-1s-.03.05 0 .05M5.24.63S5.3.61 5.3.58c0-.03-.04-.06-.07-.06-.03 0-.04.03-.04.06s.02.06.05.05"
                strokeWidth="0"
            />
        </SvgIcon>
    );
};
