import React from "react";
import {useTheme} from "@emotion/react";

import {mb} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";
import {areaFormat} from "@pg-mono/string-utils";

import {getAdditionalAreasName} from "../types/AdditionalAreasType";
import {IPropertyDetail} from "../types/IPropertyDetail";
import {getKitchenTypeNameWith} from "../types/KitchenType";
import {OfferType} from "../types/OfferType";
import {getOfferTypeNameCapital} from "../utils/get_offer_type_string";
import {getRoomsPlural} from "../utils/get_rooms_plural";

interface IProps {
    property: IPropertyDetail;
}

export const PropertyMainInfoTexts = (props: IProps) => {
    const {property} = props;
    const theme = useTheme();
    const additionalAreasText =
        property.additional_areas &&
        property.additional_areas.length > 0 &&
        property.additional_areas
            .map((item) => {
                return `${getAdditionalAreasName(item.type)} ${areaFormat(item.area)}`;
            })
            .join(", ");

    return (
        <>
            <Text variant="headline_5" css={mb(1)}>
                {`${property.rooms} ${getRoomsPlural(property.rooms)} `}
                {`${getKitchenTypeNameWith(property.kitchen_type)}, `}
                {`${areaFormat(property.area)} `}
                {additionalAreasText ? `+ ${additionalAreasText}` : ""}
            </Text>

            <Text variant="info_txt_1" color={theme.colors.gray[800]}>
                {`${property.offer.name}, `}
                {getOfferTypeNameCapital(property?.type || OfferType.FLAT)}
                {property?.type === OfferType.FLAT && property.flat_number && (
                    <>
                        {" "}
                        nr <strong>{property.flat_number}</strong>
                    </>
                )}
                {property?.type === OfferType.HOUSE && (
                    <>
                        {" "}
                        nr <strong>{property.flat_number || property.building_number}</strong>
                    </>
                )}
            </Text>

            <Text variant="info_txt_1" color={theme.colors.gray[800]}>
                {property.offer.address}
            </Text>
        </>
    );
};
