import {useEffect, useState} from "react";

import {useIsMounted} from "./use_is_mounted";

/**
 * @deprecated
 * use useUserDevice instead
 */
export const useIsMobile = (isDesktopFirst = false, mobileBreakpoint?: number) => {
    // serverside: by default we are assuming we are on mobile (mobile-first approach)
    // we can change that behaviour by passing isDesktopFirst as true,
    // use that flag ONLY for content irrelevant for SEO
    const initialValue = !isDesktopFirst;
    if (typeof window === "undefined" || typeof window.matchMedia === "undefined") {
        return initialValue;
    }
    const breakpoint = mobileBreakpoint || 1023;
    const mediaQuery = window.matchMedia(`(max-width: ${breakpoint}px)`);
    const [isMobileWidth, setIsMobileWidth] = useState<boolean>(initialValue);
    const isMounted = useIsMounted();

    const handler = () => {
        setIsMobileWidth(mediaQuery.matches);
    };

    useEffect(() => {
        if (mediaQuery.addEventListener) {
            mediaQuery.addEventListener("change", handler);
            return () => mediaQuery.removeEventListener("change", handler);
        } else {
            //<Safari 14, IE, <Edge 16 support only deprecated MediaQueryList' API. https://github.com/microsoft/TypeScript/issues/32210
            mediaQuery.addListener(handler);
            return () => mediaQuery.removeListener(handler);
        }
    }, []);

    useEffect(() => {
        if (isMounted) {
            setIsMobileWidth(mediaQuery.matches);
        }
    }, [isMounted]);
    return isMobileWidth;
};
