import {useRouteMatch} from "react-router";

import {kmAppLink, kmAppPath, SiteSlug} from "@pg-mono/km-routes";

import {NavigationLinkType} from "../types/NavigationLinkType";

export const useNavigationLinks = () => {
    const match = useRouteMatch<{site: string}>(kmAppPath.site.base);

    if (!match) {
        return [];
    }

    return [
        {
            id: NavigationLinkType.MY_OFFERS,
            label: "Moje oferty",
            path: kmAppLink.site.offers.baseWithParams({site: match.params.site as SiteSlug}),
            subLinks: [
                {
                    id: NavigationLinkType.RECOMMENDED,
                    label: "Rekomendowane",
                    path: kmAppLink.site.offers.recommendedWithParams({site: match.params.site as SiteSlug})
                },
                {id: NavigationLinkType.FAVORITE, label: "Ulubione", path: kmAppLink.site.offers.favouritesWithParams({site: match.params.site as SiteSlug})},
                {
                    id: NavigationLinkType.APPLICATION_SENT,
                    label: "Moje zapytania",
                    path: kmAppLink.site.offers.appliedWithParams({site: match.params.site as SiteSlug})
                },
                {id: NavigationLinkType.DISMISSED, label: "Odrzucone", path: kmAppLink.site.offers.dismissedWithParams({site: match.params.site as SiteSlug})}
            ]
        }
        /*
        TODO uncomment when views are ready
        {id: NavigationLinkType.PREFERENCES, label: "Preferencje", path: kmAppLink.site.preferences({site: match.params.site})},
        {id: NavigationLinkType.MARKET_DATA, label: "Dane rynkowe", path: kmAppLink.site.marketData({site: match.params.site})},
        {id: NavigationLinkType.MY_ADS, label: "Moje ogłoszenia", path: kmAppLink.site.myAds({site: match.params.site})}
        */
    ];
};
