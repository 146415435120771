import React, {ReactNode} from "react";
import {css} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {borderRadius, flex, mt, onDesktop, p} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

interface IProps {
    title: string;
    children?: ReactNode;
    okButtonText?: string;
    cancelButtonText?: string;
    onCancel?: () => void;
    onConfirmation?: () => void;
}

export const ModalConfirmLayout = (props: IProps) => {
    const {title, children, okButtonText, cancelButtonText, onCancel, onConfirmation} = props;

    return (
        <div css={innerWrap}>
            <Text mb="1.2rem" variant="headline_3">
                {title}
            </Text>

            {children ? (
                <Text as="div" variant="body_copy_1">
                    {children}
                </Text>
            ) : null}

            <div css={[flex("normal", "flex-end"), mt(3)]}>
                <Button variant="filled_primary" onClick={onConfirmation}>
                    {okButtonText ?? "Potwierdzam"}
                </Button>
                <Button variant="none_secondary" onClick={onCancel}>
                    {cancelButtonText ?? "Anuluj"}
                </Button>
            </div>
        </div>
    );
};

const innerWrap = () => css`
    position: relative;
    max-height: 100vh;
    height: 100%;
    max-width: 100vw;
    overflow: auto;
    display: flex;
    flex-direction: column;
    ${p(4)};

    ${onDesktop(css`
        height: auto;
        ${p(2, 4)};
        ${borderRadius()};
    `)};
`;
