import React, {ReactNode} from "react";
import classNames from "classnames";

import {Text} from "@pg-design/text-module";

import {IFieldState} from "../types";
import {getFieldStateColor} from "../utils/get_field_state_color";

import {fieldMessageWrapper} from "./FieldMessage.module.css";

interface IProps {
    children: ReactNode;
    fieldState?: IFieldState;
    className?: string;
}
export function FieldMessage(props: IProps) {
    const {children, fieldState} = props;

    const fieldStateColor = getFieldStateColor(fieldState);

    let messageColor = `var(${fieldStateColor})`;
    if (!fieldState || fieldState === "disabled" || fieldState === "default") {
        messageColor = "var(--colors-secondary)";
    }

    const wrapperVariables = messageColor ? {"--field-state-color": messageColor} : undefined;

    const cn = classNames(fieldMessageWrapper, props.className);
    return (
        <div style={wrapperVariables} data-name="field-message" className={cn}>
            <Text variant="info_txt_3" as="span">
                {children}
            </Text>
        </div>
    );
}
