import {hitGoogleTagManager} from "../../../tracking/gtm/gtm_hit";

const recommendationCountEvent = (recommendationCount: number) => {
    const eventData = {event: "recommendationCount", custom_label: recommendationCount};

    hitGoogleTagManager(eventData);
};

export const recommendationsAnalytics = {
    gtm: {
        recommendationCountEvent
    }
};
