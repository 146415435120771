import {prefixPath} from "@pg-mono/prefix-path";

import {USER_API_PREFIX} from "./api_prefixes";

const apiPath = prefixPath(USER_API_PREFIX, {
    oauth: prefixPath("oauth/", {
        authorize: "authorize/",
        token: "token/"
    }),
    proxy: prefixPath("proxy/", {
        favorites: prefixPath("favorites/", {
            favorite: "favorite/"
        }),
        unsubscribe: prefixPath("unsubscribe/", {
            mailing: prefixPath("mailing/", {
                getLink: "get-link/"
            })
        }),
        user: prefixPath("user/", {
            info: "info/",
            preferences: "preferences/"
        }),
        recommendations: prefixPath("recommendations/", {
            state: "state/"
        })
    }),
    users: prefixPath("users/", {
        info: "info/",
        logout: "logout/"
    }),
    session: prefixPath("session/", {
        sync: "sync/"
    })
});

export const apiUserLink = {
    favorites: {
        favorite: apiPath.proxy.favorites.favorite
    },
    oauth: {
        authorize: apiPath.oauth.authorize,
        token: apiPath.oauth.token
    },
    unsubscribe: {
        getLink: apiPath.proxy.unsubscribe.mailing.getLink
    },
    user: {
        info: apiPath.proxy.user.info,
        preferences: apiPath.proxy.user.preferences
    },
    users: {
        info: apiPath.users.info,
        logout: apiPath.users.logout
    },
    recommendations: {
        state: apiPath.proxy.recommendations.state
    },
    session: {
        sync: apiPath.session.sync
    }
};
