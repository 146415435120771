import {AxiosResponse} from "axios";

import {kmApiLink} from "@pg-mono/km-routes";

import axios from "../../../app/utils/fetch";
import {IStats} from "../types/IRecommendationsStateResponse";

export const fetchRecommendationsState = (): Promise<AxiosResponse<{state: number; last_update: string; stats: IStats}>> => {
    return axios.get(kmApiLink.recommendations.state());
};
