// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NqzyDqjiVSmjIemC8BFl {
    display: flex;
    flex-direction: column;
    row-gap: calc(2 * var(--globals-baseUnit));
}

.V8EqcydPhq06gHkUJ5qm {
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./../../packages/design_system_modules/forms/inputs/src/components/fields/image_field/ImageField.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,0CAA0C;AAC9C;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".fieldWrap {\n    display: flex;\n    flex-direction: column;\n    row-gap: calc(2 * var(--globals-baseUnit));\n}\n\n.pointer {\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
export var fieldWrap = `NqzyDqjiVSmjIemC8BFl`;
export var pointer = `V8EqcydPhq06gHkUJ5qm`;
export default ___CSS_LOADER_EXPORT___;
