import * as Yup from "yup";

Yup.setLocale({
    mixed: {
        required: "To pole nie może być puste"
    },
    string: {
        email: "Podaj poprawny adres e-mail"
    }
});
