// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.ei9COvtgHL7mYRT_XKI5 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 100%;
}

.qmZfuw2UupYKcR0fFJfi {
    display: inline-flex;
    flex-direction: column;
    margin-right: 1rem;
    margin-left: 1rem;
    flex: 1 1 auto;

    &.bdklIQUsGwA5mnLZRVmm {
        margin-left: 0;
    }

    &.Sthi3ZqH9yofSD5Vc4vI {
        margin-right: 0;
    }
}

.zaCZ63YK2VkdPXlWtsdc {
    padding-top: calc(1.5 * var(--globals-baseUnit));
    padding-bottom: calc(1.5 * var(--globals-baseUnit));
    margin-bottom: 1rem;
}

.RfRegQNxShn9cYKuy9x0 {
    background: #fff;
    /*2 to display over other normal elements*/
    z-index: 2;
}

.ewk6UCg4VxjWoghGoa58 {
    display: none;
}
`, "",{"version":3,"sources":["webpack://./../../packages/design_system_modules/forms/inputs/src/components/input_range/create_range.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,oBAAoB;IACpB,sBAAsB;IACtB,kBAAkB;IAClB,iBAAiB;IACjB,cAAc;;IAEd;QACI,cAAc;IAClB;;IAEA;QACI,eAAe;IACnB;AACJ;;AAEA;IACI,gDAAgD;IAChD,mDAAmD;IACnD,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,0CAA0C;IAC1C,UAAU;AACd;;AAEA;IACI,aAAa;AACjB","sourcesContent":["\n.rangeHolder {\n    display: flex;\n    justify-content: space-between;\n    align-items: flex-start;\n    max-width: 100%;\n}\n\n.inputHolder {\n    display: inline-flex;\n    flex-direction: column;\n    margin-right: 1rem;\n    margin-left: 1rem;\n    flex: 1 1 auto;\n\n    &.range-input-lower {\n        margin-left: 0;\n    }\n\n    &.range-input-upper {\n        margin-right: 0;\n    }\n}\n\n.separatorStyle {\n    padding-top: calc(1.5 * var(--globals-baseUnit));\n    padding-bottom: calc(1.5 * var(--globals-baseUnit));\n    margin-bottom: 1rem;\n}\n\n.inputOptionsWrapper {\n    background: #fff;\n    /*2 to display over other normal elements*/\n    z-index: 2;\n}\n\n.displayNone {\n    display: none;\n}\n"],"sourceRoot":""}]);
// Exports
export var rangeHolder = `ei9COvtgHL7mYRT_XKI5`;
export var inputHolder = `qmZfuw2UupYKcR0fFJfi`;
var _1 = `bdklIQUsGwA5mnLZRVmm`;
export { _1 as "range-input-lower" };
var _2 = `Sthi3ZqH9yofSD5Vc4vI`;
export { _2 as "range-input-upper" };
export var separatorStyle = `zaCZ63YK2VkdPXlWtsdc`;
export var inputOptionsWrapper = `RfRegQNxShn9cYKuy9x0`;
export var displayNone = `ewk6UCg4VxjWoghGoa58`;
export default ___CSS_LOADER_EXPORT___;
