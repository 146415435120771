import React, {useRef, useState} from "react";
import classNames from "classnames";
import {Property} from "csstype";

import {Placeholder} from "@pg-design/image";

import {Image} from "./Image";

import {hasErrorModule, pictureBase, pictureFit, pictureSize} from "./Picture.module.css";

interface IPictureSourceBase {
    minWidthPX: number;
    src?: string | undefined;
    width: number;
    height: number;
}

export interface IPictureSource extends IPictureSourceBase {
    src?: string | undefined;
    srcSet?: never;
}

export interface IRetinaPictureSource extends IPictureSourceBase {
    // If image is a set of sources (in our case separate source for retina display) then we need to use `srcSet` attribute on img tag
    srcSet: `${string} 1x, ${string} 2x`;
}

export interface IPictureProps {
    sources: (IRetinaPictureSource | IPictureSource)[];
    alt: string;
    loading?: "lazy";
    className?: string;
    fit?: IFit;
    fetchPriority?: "high" | "low" | "auto";
}

export type IFit = Property.ObjectPosition | `${Property.ObjectPosition} ${Property.ObjectPosition}`;

export const Picture = (props: IPictureProps) => {
    const [hasError, setHasError] = useState(false);
    const imgRef = useRef<HTMLImageElement>(null);
    const onError = () => setHasError(true); // onError does not always trigger on page load, but is working correctly on live SPA behaviour

    const sourceImages = props.sources.sort((a, b) => b.minWidthPX - a.minWidthPX);
    const smallestImage = sourceImages[sourceImages.length - 1];

    // add pictureFit
    const pictureCN = classNames(pictureBase, pictureSize, props.fit && pictureFit, hasError && hasErrorModule, props.className);

    const matchedSource = hasError && getMatchedSource(props.sources);

    const style =
        hasError && matchedSource
            ? {
                  "--width": `${matchedSource.width}px`,
                  "--height": `${matchedSource.height}px`
              }
            : {};

    return (
        <picture className={pictureCN} onError={onError} style={style}>
            <>
                {(hasError || props.sources.length === 0) && <Placeholder />}

                {sourceImages.map(({minWidthPX, width, height, ...restMedia}) => {
                    const src = restMedia.srcSet || restMedia.src;
                    const aspectStyle = {"--aspect-ratio": `${(height / width) * 100}%`};

                    return <source key={minWidthPX} media={`(min-width: ${minWidthPX}px)`} style={aspectStyle} width={width} height={height} srcSet={src} />;
                })}

                <Image
                    alt={props.alt}
                    loading={props.loading}
                    height={smallestImage.height}
                    width={smallestImage.width}
                    fit={props.fit}
                    ref={imgRef}
                    hasError={hasError}
                    fetchPriority={props.fetchPriority}
                    onLoad={(img) => {
                        /**
                         * fix: we did get some image urls that returned 403 from amazon.
                         * onError` callback should set the state to show placeholder, but it does not always trigger right after a page load.
                         * Change on error is forced here.
                         */
                        if (img.currentTarget.complete && img.currentTarget.naturalWidth === 0) {
                            setHasError(true);
                        }

                        if (img.currentTarget.complete && img.currentTarget.naturalWidth !== 0) {
                            setHasError(false);
                        }
                    }}
                    {...getSrcProp(smallestImage)}
                />
            </>
        </picture>
    );
};

const getSrcProp = (media: IPictureSource | IRetinaPictureSource) => ("srcSet" in media ? {srcSet: media.srcSet} : {src: media.src});

/**
 * function returns the same source data that browser would match via the <picture> tag. Should be used only clientside.
 */
const getMatchedSource = (sources: (IPictureSource | IRetinaPictureSource)[]) => {
    const sortedSources = sources.sort((a, b) => b.minWidthPX - a.minWidthPX);

    for (const source of sortedSources) {
        if (window.matchMedia(`(min-width: ${source.minWidthPX}px)`).matches) {
            return source;
        }
    }

    // return smallest image if no source matched
    return sortedSources[sortedSources.length - 1];
};
