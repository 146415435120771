import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const CityIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M8.467 3.667h-1.6V1H3.133v1.6H1V9h8V3.667h-.533Zm-6.934 4.8V3.133h1.6v5.334h-1.6Zm2.134 0V1.533h2.666v6.934H3.667Zm3.2 0V4.2h1.6v4.267h-1.6Z" />
            <path d="M2.6 3.667h-.533V4.2H2.6v-.533ZM4.733 2.6H4.2v.533h.533V2.6ZM5.8 2.6h-.533v.533H5.8V2.6ZM4.733 3.667H4.2V4.2h.533v-.533ZM5.8 3.667h-.533V4.2H5.8v-.533ZM4.733 4.733H4.2v.533h.533v-.533ZM5.8 4.733h-.533v.533H5.8v-.533ZM4.733 5.8H4.2v.533h.533V5.8ZM5.8 5.8h-.533v.533H5.8V5.8ZM4.733 6.867H4.2V7.4h.533v-.533ZM5.8 6.867h-.533V7.4H5.8v-.533ZM2.6 4.733h-.533v.533H2.6v-.533ZM7.933 4.733H7.4v.533h.533v-.533ZM2.6 5.8h-.533v.533H2.6V5.8ZM2.6 6.867h-.533V7.4H2.6v-.533ZM7.933 5.8H7.4v.533h.533V5.8ZM7.933 6.867H7.4V7.4h.533v-.533Z" />
        </SvgIcon>
    );
};
