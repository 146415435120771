// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@layer packages {
    .ggnbSi_M6GaCV_Lm1gTp {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
`, "",{"version":3,"sources":["webpack://./../../packages/design_system_modules/core/icons/src/components/SvgIconWrapper.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,aAAa;QACb,uBAAuB;QACvB,mBAAmB;IACvB;AACJ","sourcesContent":["@layer packages {\n    .svgIconWrapper {\n        display: flex;\n        justify-content: center;\n        align-items: center;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export var svgIconWrapper = `ggnbSi_M6GaCV_Lm1gTp`;
export default ___CSS_LOADER_EXPORT___;
