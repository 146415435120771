// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mcTD5zSr3EMPsSpCLh_K {
    position: relative;
    padding: calc(var(--globals-baseUnit) * 1.5) calc(var(--globals-baseUnit) * 1.5) calc(var(--globals-baseUnit) * 1.5) calc(var(--globals-baseUnit) * 2);
}

.Lx_l_DIJKV01HG6EeO1u {
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./../../packages/design_system_modules/forms/inputs/src/components/fields/time_field/TimeInput.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,sJAAsJ;AAC1J;;AAEA;IACI,WAAW;AACf","sourcesContent":[".inputWrapperStyle {\n    position: relative;\n    padding: calc(var(--globals-baseUnit) * 1.5) calc(var(--globals-baseUnit) * 1.5) calc(var(--globals-baseUnit) * 1.5) calc(var(--globals-baseUnit) * 2);\n}\n\n.inputCoreStyle {\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export var inputWrapperStyle = `mcTD5zSr3EMPsSpCLh_K`;
export var inputCoreStyle = `Lx_l_DIJKV01HG6EeO1u`;
export default ___CSS_LOADER_EXPORT___;
