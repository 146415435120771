// ResponseError
const ERROR_400 = "Bad Request";
const ERROR_401 = "Unauthorized";
const ERROR_403 = "Forbidden";
const ERROR_404 = "Not Found";
const ERROR_504 = "Gateway Timeout";
const ERROR_5XX = "Server Error";
const ERROR_UNKNOWN_STATUS = "Unknown Status Error";
const ERROR_STALLED = "Response stalled";
// non ResponseError
const ERROR_TIMEOUT = "Response Timeout";
const ERROR_UNKNOWN_ERROR = "Unknown Error";

export class ResponseError {
    public name: string;
    public message: string;
    public response: Response;
    constructor(name: string, message: string, response: Response) {
        this.name = name;
        this.message = message;
        this.response = response;
    }
}

export class Response400 extends ResponseError {
    public appError: IAppError | null;

    constructor(response: Response, message: string) {
        super(ERROR_400, message, response);
        this.appError = null;
    }

    public async initAppError() {
        const json = await this.response.json();
        this.appError = getAppErrors(this.response.status, json);
    }
}

export class Response401 extends ResponseError {
    constructor(response: Response, message: string) {
        super(ERROR_401, message, response);
    }
}

export class Response403 extends ResponseError {
    public responseError: Object | null;

    constructor(response: Response, message: string) {
        super(ERROR_403, message, response);
        this.responseError = null;
    }

    public async initResponseError() {
        this.responseError = await this.response.json();
    }
}

export class Response404 extends ResponseError {
    constructor(response: Response, message: string) {
        super(ERROR_404, message, response);
    }
}

export class Response504 extends ResponseError {
    constructor(response: Response, message: string) {
        super(ERROR_504, message, response);
    }
}

export class Response5xx extends ResponseError {
    constructor(response: Response, message: string) {
        super(ERROR_5XX, message, response);
    }
}

export class ResponseUnknownStatus extends ResponseError {
    constructor(response: Response, message: string) {
        super(ERROR_UNKNOWN_STATUS, message, response);
    }
}

export class ResponseStalledError extends ResponseError {
    constructor(response: Response, message: string) {
        super(ERROR_STALLED, message, response);
    }
}

export class ResponseTimeoutError {
    public name: string;
    public url: string;
    constructor(url: string) {
        this.name = ERROR_TIMEOUT;
        this.url = url;
    }
}

export class ResponseUnknownError {
    public name: string;
    public originalError: Error;
    public url: string;
    constructor(originalError: Error, url: string) {
        this.name = ERROR_UNKNOWN_ERROR;
        this.originalError = originalError;
        this.url = url;
    }
}

/**
 * Catch helpers
 */
const createCatchResponseError =
    <TError extends {name: string}>(ErrorClass: Function) =>
    <TReturn = unknown>(catchCallback: (err: TError) => TReturn) =>
    (err: TError): TReturn => {
        if (err instanceof ErrorClass) {
            return catchCallback(err);
        } else {
            // throw error to be handled down the promise execution path
            throw err;
        }
    };

export const catch400 = createCatchResponseError<Response400>(Response400);
export const catch401 = createCatchResponseError<Response401>(Response401);
export const catch403 = createCatchResponseError<Response403>(Response403);
export const catch404 = createCatchResponseError<Response404>(Response404);
export const catch504 = createCatchResponseError<Response504>(Response504);
export const catch5xx = createCatchResponseError<Response5xx>(Response5xx);
export const catchUnknownStatus = createCatchResponseError<ResponseUnknownStatus>(ResponseUnknownStatus);
export const catchStalled = createCatchResponseError<ResponseStalledError>(ResponseStalledError);
export const catchUnknownError = createCatchResponseError<ResponseUnknownError>(ResponseUnknownError);
export const catchTimeout = createCatchResponseError<ResponseTimeoutError>(ResponseTimeoutError);

/**
 * Init 400 helper
 */
export interface IAppError {
    status: number;
    fieldErrors: Record<string, string[]>;
    nonFieldErrors: string[];
}

function getAppErrors(status: number, responseJson: Record<string, string[]>): IAppError {
    const appError: IAppError = {
        status,
        fieldErrors: {},
        nonFieldErrors: []
    };
    for (const key in responseJson) {
        // eslint-disable-next-line no-prototype-builtins
        if (responseJson.hasOwnProperty(key)) {
            const err = responseJson[key];
            if (key === "non_field_errors") {
                appError.nonFieldErrors = err;
            } else {
                appError.fieldErrors[key] = err;
            }
        }
    }
    return appError;
}
