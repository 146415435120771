import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const AdditionalAreaIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M6.867 5.91v.534h-.534v.533h.534v.534H7.4v-.534h.533v-.533H7.4V5.91h-.533Z" />
            <path d="M6.867 4.844v-.911L4.31 1.377l-.377.377 2.4 2.4v.69H5.267v3.2H1.533v-3.89l2.4-2.4.377-.377L3.933 1 1 3.933v4.644h8V4.844H6.867Zm1.6 3.2H5.8V5.377h2.667v2.667Z" />
        </SvgIcon>
    );
};
