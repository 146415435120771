import {notifyBugsnagClient} from "@pg-mono/bugsnag-client";
import {SiteSlug} from "@pg-mono/km-routes";

import {ActivitySource} from "../constants/ActivitySource";

export function getSiteSlugFromActivitySource(activitySource: ActivitySource) {
    switch (activitySource) {
        case ActivitySource.gethome:
            return SiteSlug.GH;
        case ActivitySource.rynekpierwotny:
            return SiteSlug.RP;
        default:
            const errorMsg = `Unknown activity source: ${activitySource}`;
            notifyBugsnagClient(new Error(errorMsg), errorMsg);
            console.warn(errorMsg);
            return SiteSlug.KM;
    }
}
