import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {sub} from "date-fns";

import {apiBigDataLink} from "@pg-mono/rp-api-routes";
import {dateTimeFormat, formatDate} from "@pg-mono/string-utils";

import {bigDataApiUrl} from "../../../app/utils/read_environment_variables";
import {IRegionEstimatedPrice} from "../types/IRegionEstimatedPrice";

interface IRegionEstimatePrices {
    avgPriceM2Min: number;
    avgPriceM2Max: number;
    avgPriceM2HouseMin: number;
    avgPriceM2HouseMax: number;
}

export const bigDataApi = createApi({
    reducerPath: "bd",
    baseQuery: fetchBaseQuery(),
    tagTypes: ["RegionEstimatePrice"],
    endpoints: (builder) => ({
        getRegionEstimatePrice: builder.query<IRegionEstimatePrices, {slug_city: string; slug_district?: string}>({
            query: (params) => {
                const isDistrict = !!params.slug_district;
                const createUrl = isDistrict ? apiBigDataLink.monthDistrictsStats.base : apiBigDataLink.monthCityStats.base;

                // Statistics DB hasn't full data for current month, so we need to subtract one month from current date.
                const endDate = sub(new Date(), {months: 1});

                return {
                    url: createUrl(bigDataApiUrl),
                    params: {
                        ...(params ?? {}),
                        date_start: formatDate(sub(endDate, {months: 12}).toISOString(), dateTimeFormat.dashedDateFormat),
                        date_end: formatDate(endDate.toISOString(), dateTimeFormat.dashedDateFormat),
                        scenario: "rp-price-avg"
                    }
                };
            },
            providesTags: ["RegionEstimatePrice"],
            transformResponse: (response: IRegionEstimatedPrice[]) => {
                const avgPricesM2 = response.map((item) => item.avg_price_m2).filter(Boolean);
                const avgPricesM2House = response.map((item) => item.avg_price_m2_house).filter(Boolean);

                return {
                    avgPriceM2Min: Math.min(...avgPricesM2) || 0,
                    avgPriceM2Max: Math.max(...avgPricesM2) || 0,
                    avgPriceM2HouseMin: Math.min(...avgPricesM2House) || 0,
                    avgPriceM2HouseMax: Math.max(...avgPricesM2House) || 0
                };
            }
        })
    })
});

export const {useGetRegionEstimatePriceQuery} = bigDataApi;
