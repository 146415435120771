import {useNavigationLinks} from "../hooks/use_navigation_links";
import {NavigationLink} from "./NavigationLink";

export const NavigationLinks = () => {
    const links = useNavigationLinks();

    return (
        <div>
            {links.map((link) => {
                return <NavigationLink key={link.id} id={link.id} label={link.label} path={link.path} subLinks={link.subLinks} />;
            })}
        </div>
    );
};
