import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const AreaIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M1 1.534h.534V5.8H1v.533h1.6V5.8h-.534V1.534H2.6V1H1v.534ZM8.467 7.934H4.2V7.4h-.534V9h.534v-.534h4.266V9H9V7.4h-.533v.534ZM3.667 1v5.333h5.33V1h-5.33Zm4.8 4.798H4.199V1.534h4.268v4.264Z" />
        </SvgIcon>
    );
};
