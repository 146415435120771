export enum AdditionalAreasType {
    BALCONY = 1,
    LOGGIA = 2,
    TERRACE = 3,
    GARDEN = 4
}

export const getAdditionalAreasName = (type: AdditionalAreasType) => {
    switch (type) {
        case AdditionalAreasType.BALCONY:
            return "balkon";
        case AdditionalAreasType.LOGGIA:
            return "loggia";
        case AdditionalAreasType.TERRACE:
            return "taras";
        case AdditionalAreasType.GARDEN:
            return "ogródek";
        default:
            return "";
    }
};
