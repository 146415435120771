// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.f7tE9H1rdbmORa_u1iCq {
    cursor: text;
}

.tIvJu9wYbeM2ueBQi9wT {
    cursor: inherit;
}
`, "",{"version":3,"sources":["webpack://./../../packages/design_system_modules/forms/inputs/src/components/input/Input.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".inputBase {\n    cursor: text;\n}\n\n.inputBaseDisabled {\n    cursor: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
export var inputBase = `f7tE9H1rdbmORa_u1iCq`;
export var inputBaseDisabled = `tIvJu9wYbeM2ueBQi9wT`;
export default ___CSS_LOADER_EXPORT___;
