import React, {FC, useEffect} from "react";
import ReactModal from "react-modal";

import {defaultModalParams} from "../constants/defaults";
import {IModalProps} from "../types/IModalProps";
import {ModalCloseButton} from "./atoms/ModalCloseButton";
import {ModalContent} from "./atoms/ModalContent";
import {ModalHeader} from "./atoms/ModalHeader";
import {contentElement} from "./core/contentElement";
import {overlayElement} from "./core/overlayElement";
import {ModalLayoutEmptyResponsive} from "./layouts/ModalLayoutEmptyResponsive";
import {ModalLayoutPrompt} from "./layouts/ModalLayoutPrompt";
import {ModalContextProvider} from "./ModalContext";
import {ModalGlobalStyles} from "./ModalGlobalStyles";

interface ModalAtoms {
    Header: typeof ModalHeader;
    CloseButton: typeof ModalCloseButton;
    Content: typeof ModalContent;
}

interface ModalLayouts {
    Layout: {
        EmptyResponsive: typeof ModalLayoutEmptyResponsive;
        Prompt: typeof ModalLayoutPrompt;
    };
}

type ModalType = FC<IModalProps> & ModalAtoms & ModalLayouts;

export const Modal: ModalType = (props) => {
    const closeTimeoutMS = props.closeTimeout ?? 200;

    useEffect(() => {
        const rootId = props.modalRootId ?? defaultModalParams["defaultRootSelector"];

        if (rootId) {
            ReactModal.setAppElement(rootId);
        }
    }, [props.modalRootId]);

    return (
        <ModalContextProvider value={{...defaultModalParams, ...props}}>
            <ModalGlobalStyles lockBodyScroll={props.isOpen} />

            <ReactModal
                closeTimeoutMS={closeTimeoutMS}
                isOpen={Boolean(props.isOpen)}
                ariaHideApp={false}
                contentLabel={props.contentLabel}
                shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick}
                shouldCloseOnEsc={props.shouldCloseOnEsc}
                contentElement={contentElement(props)}
                overlayElement={overlayElement(props)}
                onRequestClose={props?.onModalClose}
                onAfterOpen={props?.onAfterOpen}
                onAfterClose={props?.onAfterClose}
                className={props.className}
            >
                {props.children}
            </ReactModal>
        </ModalContextProvider>
    );
};

Modal.Header = ModalHeader;
Modal.CloseButton = ModalCloseButton;
Modal.Content = ModalContent;

Modal.Layout = {
    EmptyResponsive: ModalLayoutEmptyResponsive,
    Prompt: ModalLayoutPrompt
};
