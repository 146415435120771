// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@layer packages {
    .aIKZClyfVDReJ97ZZQZm {
        display: block;
        max-width: 100%;
        width: auto;
        height: auto;
    }

    .tQdwDZJjTAdsTcabP1cq {
        --object-position: 50% 50%;
        object-position: var(--object-position);
        object-fit: cover;
        height: 100%;
        width: 100%;
    }

    .CQgqi7dxRzwv1JD5rctd {
        display: none;
    }
}
`, "",{"version":3,"sources":["webpack://./../../packages/design_system_modules/core/picture/src/components/Image.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,cAAc;QACd,eAAe;QACf,WAAW;QACX,YAAY;IAChB;;IAEA;QACI,0BAA0B;QAC1B,uCAAuC;QACvC,iBAAiB;QACjB,YAAY;QACZ,WAAW;IACf;;IAEA;QACI,aAAa;IACjB;AACJ","sourcesContent":["@layer packages {\n    .image {\n        display: block;\n        max-width: 100%;\n        width: auto;\n        height: auto;\n    }\n\n    .imageFit {\n        --object-position: 50% 50%;\n        object-position: var(--object-position);\n        object-fit: cover;\n        height: 100%;\n        width: 100%;\n    }\n\n    .hasErrorModule {\n        display: none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export var image = `aIKZClyfVDReJ97ZZQZm`;
export var imageFit = `tQdwDZJjTAdsTcabP1cq`;
export var hasErrorModule = `CQgqi7dxRzwv1JD5rctd`;
export default ___CSS_LOADER_EXPORT___;
