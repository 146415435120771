import {Attribute} from "../types/Attribute";
import {EventNames} from "../types/EventNames";
import {userComUserengage} from "../user_com_userengage";
import {updateUserAttributes} from "./update_user_attributes";

export const userLogin = (uuid: string, email: string) => {
    const payload = {
        event_name: EventNames.UserLogin,
        [Attribute.USER_ID]: uuid,
        [Attribute.EMAIL]: email
    };

    userComUserengage(EventNames.UserLogin, payload);
    updateUserAttributes(payload);
};
