import React, {ElementType, forwardRef, type PropsWithChildren} from "react";
import cn from "classnames";

import * as styles from "./Col.module.css";

export type OneToFour = 1 | 2 | 3 | 4;
export type OneToTwelve = OneToFour | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export type OneToSixteen = OneToTwelve | 13 | 14 | 15 | 16;

interface IProps extends React.HTMLAttributes<HTMLElement> {
    noGutters?: boolean;
    sm?: OneToFour;
    smOffset?: OneToFour;
    md?: OneToTwelve;
    mdOffset?: OneToTwelve;
    lg?: OneToSixteen;
    lgOffset?: OneToSixteen;
    as?: ElementType;
}

type ClassName = keyof typeof styles;

const getColSmName = (value: number, offset: boolean = false) => `colSm${offset ? "Offset" : ""}${value}Of4` as ClassName;
const getColMdName = (value: number, offset: boolean = false) => `colMd${offset ? "Offset" : ""}${value}Of12` as ClassName;
const getColLgName = (value: number, offset: boolean = false) => `colLg${offset ? "Offset" : ""}${value}Of16` as ClassName;

export const Col = forwardRef((props: PropsWithChildren<IProps>, ref) => {
    const {sm, smOffset, md, mdOffset, lg, lgOffset, noGutters, as, className, ...restProps} = props;

    const Element = as || "div";

    const classes = cn(
        styles.col,
        sm && styles[getColSmName(sm)],
        smOffset && styles[getColSmName(smOffset, true)],
        md && styles[getColMdName(md)],
        mdOffset && styles[getColMdName(mdOffset, true)],
        lg && styles[getColLgName(lg)],
        lgOffset && styles[getColLgName(lgOffset, true)],
        noGutters && styles.colNoGutters,
        className
    );

    return (
        <Element className={classes} ref={ref} {...restProps}>
            {props.children}
        </Element>
    );
});
