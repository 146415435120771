import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const LogoutIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M5.286 4.197h-.571V1h.571v3.197ZM6.402 1.77V1.77L6.4 1.77h.002Z" />
            <path d="M6.402 2.346V1.77C7.919 2.3 9 3.665 9 5.267 9 7.329 7.21 9 5 9S1 7.329 1 5.267c0-1.592 1.068-2.951 2.572-3.488v.579c-1.181.506-2 1.618-2 2.909 0 1.767 1.535 3.199 3.428 3.199s3.429-1.433 3.429-3.2c0-1.301-.833-2.42-2.027-2.92Z" />
        </SvgIcon>
    );
};
