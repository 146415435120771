/*
 * Google Maps API requires a callback parameter, which is a function.
 * In this file a global "empty" function is created which can be passed as a parameter.
 */
declare global {
    interface Window {
        initGoogleMap: () => void;
    }
}

export const initGoogleMap = (): void => {
    if (window) {
        const win = window as Window;
        win.initGoogleMap = win.initGoogleMap || initMap;
    }
};

const initMap = () => {
    return;
};
