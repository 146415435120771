import React, {type ElementType, type PropsWithChildren} from "react";
import cn from "classnames";

import * as styles from "./Hidden.module.css";

interface IProps {
    className?: string;
    as?: ElementType;
    on: "mobile" | "desktop";
}

export const Hidden = (props: PropsWithChildren<IProps>) => {
    const Element = props.as || "div";

    const classes = cn({[styles.hiddenMobile]: props.on === "mobile", [styles.hiddenDesktop]: props.on === "desktop"}, props.className);

    return <Element className={classes}>{props.children}</Element>;
};
