import {configureStore} from "@reduxjs/toolkit";

import {middleware} from "./middleware";
import {reducer} from "./reducer";

export const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware),
    devTools: true
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
