import {bigDataApi} from "../../modules/big_data/api/big_data_api";
import {openStreetMapApi} from "../../modules/map/api/open_street_map_api";
import {recommendationsApi} from "../../modules/properties/api/recommendations_api";
import {userApi} from "../../modules/user/api/user_api";
import {rtkQueryErrorHandlerMiddleware} from "./middlewares/rtk_query_error_handler_middleware";

export const middleware = [
    rtkQueryErrorHandlerMiddleware,
    bigDataApi.middleware,
    userApi.middleware,
    recommendationsApi.middleware,
    openStreetMapApi.middleware
];
