import {IStats} from "../../properties/types/IRecommendationsStateResponse";
import {StatusType} from "../../properties/types/StatusType";
import {NavigationLinkType} from "../types/NavigationLinkType";

export const getListCount = (type: StatusType | NavigationLinkType, stats: IStats) => {
    switch (type) {
        case NavigationLinkType.RECOMMENDED:
        case StatusType.RECOMMENDED:
            return {count: stats.recommended_count - stats.sold_recommendations_count, new: stats.not_seen_count - stats.sold_not_seen_count};
        case NavigationLinkType.FAVORITE:
        case StatusType.FAVORITE:
            return {count: stats.favorite_count, new: null};
        case NavigationLinkType.APPLICATION_SENT:
        case StatusType.APPLICATION_SENT:
            return {count: stats.application_sent_count + (stats.application_count || 0), new: null};
        case NavigationLinkType.DISMISSED:
        case StatusType.DISMISSED:
            return {count: stats.dismissed_count, new: null};
        default:
            return {count: stats.all_count - stats.sold_recommendations_count, new: null};
    }
};
