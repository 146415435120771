import type {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";

export interface IAuthStore {
    redirectPath: string | null;
}

const initialState: IAuthStore = {
    redirectPath: null
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setRedirectPath: (state, action: PayloadAction<IAuthStore["redirectPath"]>) => {
            return {...state, redirectPath: action.payload};
        }
    }
});

export const {setRedirectPath} = authSlice.actions;
