// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@layer packages {
    .BDPzvHoa_Ikc0bIdIpGf {
        margin-right: auto;
        margin-left: auto;
        padding-left: var(--globals-baseUnit);
        padding-right: var(--globals-baseUnit);
        max-width: 360px;

        @media (min-width: 0px) {
            padding-left: var(--grid-sm-offsetPx);
            padding-right: var(--grid-sm-offsetPx);
            max-width: var(--grid-sm-widthPx);
        }

        @media (min-width: 1024px) {
            padding-left: var(--grid-md-offsetPx);
            padding-right: var(--grid-md-offsetPx);
            max-width: var(--grid-md-widthPx);
        }

        @media (min-width: 1366px) {
            padding-left: var(--grid-lg-offsetPx);
            padding-right: var(--grid-lg-offsetPx);
            max-width: var(--grid-lg-widthPx);
        }
    }

    .dLb0d5QQnBbRLBwDYfFQ {
        margin-right: auto;
        margin-left: auto;
        width: 100%;
    }
}
`, "",{"version":3,"sources":["webpack://./../../packages/design_system_modules/core/grid/src/components/Container.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,kBAAkB;QAClB,iBAAiB;QACjB,qCAAqC;QACrC,sCAAsC;QACtC,gBAAgB;;QAEhB;YACI,qCAAqC;YACrC,sCAAsC;YACtC,iCAAiC;QACrC;;QAEA;YACI,qCAAqC;YACrC,sCAAsC;YACtC,iCAAiC;QACrC;;QAEA;YACI,qCAAqC;YACrC,sCAAsC;YACtC,iCAAiC;QACrC;IACJ;;IAEA;QACI,kBAAkB;QAClB,iBAAiB;QACjB,WAAW;IACf;AACJ","sourcesContent":["@layer packages {\n    .container {\n        margin-right: auto;\n        margin-left: auto;\n        padding-left: var(--globals-baseUnit);\n        padding-right: var(--globals-baseUnit);\n        max-width: 360px;\n\n        @media (min-width: 0px) {\n            padding-left: var(--grid-sm-offsetPx);\n            padding-right: var(--grid-sm-offsetPx);\n            max-width: var(--grid-sm-widthPx);\n        }\n\n        @media (min-width: 1024px) {\n            padding-left: var(--grid-md-offsetPx);\n            padding-right: var(--grid-md-offsetPx);\n            max-width: var(--grid-md-widthPx);\n        }\n\n        @media (min-width: 1366px) {\n            padding-left: var(--grid-lg-offsetPx);\n            padding-right: var(--grid-lg-offsetPx);\n            max-width: var(--grid-lg-widthPx);\n        }\n    }\n\n    .containerFluid {\n        margin-right: auto;\n        margin-left: auto;\n        width: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export var container = `BDPzvHoa_Ikc0bIdIpGf`;
export var containerFluid = `dLb0d5QQnBbRLBwDYfFQ`;
export default ___CSS_LOADER_EXPORT___;
