import qs from "query-string";

export type IQueryValue = string | string[] | number | number[] | boolean | undefined | null;
export type IQuery = Record<string, IQueryValue>;

export const appendQueryString = <Query = object>(currentUrl: string, inputQuery: Query): string => {
    const {"": _omit, ...query} = inputQuery as {
        ""?: unknown;
    };

    if (isEmpty(query)) {
        return currentUrl;
    }

    const search = qs.stringify(query); // query may have undefined values & returns empty string
    if (search === "") {
        return currentUrl;
    }

    // append query string to currentUrl
    return currentUrl.indexOf("?") === -1 ? `${currentUrl}?${search}` : `${currentUrl}&${search}`;
};

const isEmpty = (obj: Record<string, unknown>) => Object.keys(obj).length === 0 && obj.constructor === Object;
