import React, {type PropsWithChildren} from "react";
import cn from "classnames";
import {type Property} from "csstype";

import * as styles from "./CenterBox.module.css";

interface IProps {
    className?: string;
    height?: Property.Height;
}

export const CenterBox = (props: PropsWithChildren<IProps>) => {
    return (
        <div className={cn(styles.centerBox, props.className)} style={{height: props.height || "100%", minHeight: props.height || "100%"}}>
            <div className={styles.centerBoxContent}>{props.children}</div>
        </div>
    );
};
