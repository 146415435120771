/**
 * Server Request Headers Service
 *
 * `getHeaders` is used in request.ts to pass client headers through Node to Rest API
 */
const API_REQUEST_HEADER_KEYS = ["Cookie", "Referer", "x-cache-force-miss"].map((str) => str.toLowerCase());

export interface IServerRequestHeadersService {
    appendHeaders: (appendHeaders: Record<string, string>) => void;
    getHeaders: () => Record<string, string>;
}

export const createServerRequestHeadersService = (initHeaders: Record<string, string>): IServerRequestHeadersService => {
    let requestHeaders: Record<string, string> = initHeaders;

    return {
        appendHeaders: (appendHeaders: Record<string, string>) => {
            if (process.env.EXEC_ENV === "browser") {
                return; // noop for browser
            }

            requestHeaders = {...requestHeaders, ...appendHeaders};
        },
        getHeaders: () => {
            if (process.env.EXEC_ENV === "browser") {
                return {}; // noop for browser
            }

            return pick(requestHeaders, API_REQUEST_HEADER_KEYS);
        }
    };
};

/**
 * Helpers
 */
const pick = (obj: Record<string, unknown>, keys: string[]) =>
    Object.entries(obj)
        .filter(([key]) => keys.includes(key))
        .reduce((obj, [key, val]) => Object.assign(obj, {[key]: val}), {});
