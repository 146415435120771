import React from "react";
import Bugsnag, {Plugin} from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

// Remember that only "staging" and "production" release stages will report errors
export const initClientBugsnag = (apiKey: string, releaseStage: "staging" | "production" | "development") => {
    if (Bugsnag.isStarted()) {
        return;
    }

    Bugsnag.start({
        apiKey,
        releaseStage,
        enabledReleaseStages: ["production", "staging"],
        plugins: [new BugsnagPluginReact() as Plugin]
    });
};

export const getBugsnagErrorBoundary = () => {
    // BANG: if bugsnag for some reason does not work properly we need to throw an Error
    return Bugsnag.getPlugin("react")!.createErrorBoundary(React); // eslint-disable-line @typescript-eslint/no-non-null-assertion
};
