import React from "react";
import {useDispatch, useSelector} from "react-redux";

import {Notifications as DSNotifications} from "@pg-design/notifications";

import {RootState} from "../../../app/store/store";
import {removeNotification} from "../state/notifications_slice";

export const Notifications = () => {
    const dispatch = useDispatch();

    const notifications = useSelector((state: RootState) => state.notifications);

    return <DSNotifications notifications={notifications} removeNotification={(id) => dispatch(removeNotification(id))} />;
};
