import axios, {AxiosError, AxiosResponse} from "axios";

import {fetchErrorHandler} from "./fetch_error_handler";
import {getFetchHeaders} from "./get_fetch_headers";

const instance = axios.create({
    baseURL: "/",
    headers: getFetchHeaders()
});

instance.defaults.headers.post["Content-Type"] = "application/json";

const onResponse = (response: AxiosResponse): AxiosResponse => response;

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
    fetchErrorHandler(error);

    return Promise.reject(error);
};

instance.interceptors.response.use(onResponse, onResponseError);

export default instance;
