// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ulU3UTnoMaXMQx5qDOBw {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
}

.Dr5XOk0JLK9vXiqE2vV_ {
    position: absolute;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
}

.nMgHCZFzmHLsIBDaAgh6 {
    white-space: nowrap;
    padding-left: calc(2 * var(--globals-baseUnit));
    cursor: pointer;
}

.qB5gSyDMNCdfzCwKpQAk {
    position: relative;
    width: 100%;
    flex-basis: 100%;
    cursor: pointer;
}

.OpcaahTfJ7y0xEpAKEXb {
    padding-left: calc(1.5 * var(--globals-baseUnit));
    padding-right: calc(1.5 * var(--globals-baseUnit));
}

.QbaxWC4lahZQR1kdIzkq {
    position: relative;
}

.deN4aBGjv5FEhPYNl0GG {
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./../../packages/design_system_modules/forms/inputs/src/components/fields/file_field/FileInput.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,OAAO;IACP,QAAQ;IACR,MAAM;IACN,SAAS;IACT,UAAU;IACV,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,mBAAmB;IACnB,+CAA+C;IAC/C,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,iDAAiD;IACjD,kDAAkD;AACtD;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".inputFileStyle {\n    position: absolute;\n    left: 0;\n    right: 0;\n    top: 0;\n    bottom: 0;\n    opacity: 0;\n    cursor: pointer;\n}\n\n.fileNameStyle {\n    position: absolute;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden;\n    max-width: 100%;\n}\n\n.selectFileInfo {\n    white-space: nowrap;\n    padding-left: calc(2 * var(--globals-baseUnit));\n    cursor: pointer;\n}\n\n.placeholder {\n    position: relative;\n    width: 100%;\n    flex-basis: 100%;\n    cursor: pointer;\n}\n\n.text {\n    padding-left: calc(1.5 * var(--globals-baseUnit));\n    padding-right: calc(1.5 * var(--globals-baseUnit));\n}\n\n.inputWrapperStyle {\n    position: relative;\n}\n\n.rightElementStyle {\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
export var inputFileStyle = `ulU3UTnoMaXMQx5qDOBw`;
export var fileNameStyle = `Dr5XOk0JLK9vXiqE2vV_`;
export var selectFileInfo = `nMgHCZFzmHLsIBDaAgh6`;
export var placeholder = `qB5gSyDMNCdfzCwKpQAk`;
export var text = `OpcaahTfJ7y0xEpAKEXb`;
export var inputWrapperStyle = `QbaxWC4lahZQR1kdIzkq`;
export var rightElementStyle = `deN4aBGjv5FEhPYNl0GG`;
export default ___CSS_LOADER_EXPORT___;
