import {useEffect, useState} from "react";
import {useHistory} from "react-router";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {
    backgroundColor,
    calculateRemSize,
    display,
    flex,
    flexAbsoluteCenter,
    flexDirection,
    h100,
    ml,
    p,
    pb,
    pointer,
    position,
    w100,
    zIndex
} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

import {NavigationAccountSettings} from "./NavigationAccountSettings";
import {NavigationLinks} from "./NavigationLinks";

export const HamburgerMenu = () => {
    const [isOpen, setIsOpen] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (isOpen) {
            setIsOpen(false);
        }
    }, [history.location]);

    return (
        <HamburgerMenuHolder isOpen={isOpen}>
            <HeaderWrapper isOpen={isOpen}>
                <HeaderText variant="body_copy_2" isOpen={isOpen}>
                    Menu
                </HeaderText>

                <HamburgerWrapper isOpen={isOpen}>
                    <div css={hamburger} className={isOpen ? "is-active" : ""} onClick={() => setIsOpen(!isOpen)} />
                </HamburgerWrapper>
            </HeaderWrapper>

            <MenuWrapper isOpen={isOpen}>
                <div css={linksWrapper}>
                    <NavigationLinks />

                    <NavigationAccountSettings />
                </div>
            </MenuWrapper>
        </HamburgerMenuHolder>
    );
};

const HamburgerMenuHolder = styled.div<{isOpen: boolean}>`
    width: ${(props) => (props.isOpen ? "100%" : "unset")};
    ${zIndex(20)};
    ${(props) => (props.isOpen ? position("fixed") : position("absolute"))};
    top: 0;
    right: 0;
    overscroll-behavior: contain;
`;

const HeaderWrapper = styled.div<{isOpen: boolean}>`
    ${(props) => backgroundColor(props.isOpen ? props.theme.colors.gray[100] : "transparent")};
    ${flex("center", "space-between")};
    ${w100};
`;

const HeaderText = styled(Text)<{isOpen: boolean}>`
    ${(props) => display(props.isOpen ? "block" : "none")};
    ${ml(3)};
`;

const HamburgerWrapper = styled.div<{isOpen: boolean}>`
    ${flexAbsoluteCenter};
    width: ${calculateRemSize(8)};
    height: ${calculateRemSize(8)};
    ${(props) => backgroundColor(props.isOpen ? props.theme.colors.gray[200] : "transparent")};
`;

const hamburger = css`
    ${pointer};
    ${position("relative")};
    width: 24px;
    height: 20px;
    background: transparent;
    border-top: 2px solid;
    border-bottom: 2px solid;
    transition: all 0.25s ease-in-out;

    &:before,
    &:after {
        content: "";
        ${position("absolute")};
        ${display("block")};
        ${backgroundColor("currentColor")};
        ${w100};
        height: 2px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: transform 0.25s ease-in-out;
    }

    &.is-active {
        border-color: transparent;

        &:before {
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &:after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }
`;

const MenuWrapper = styled.div<{isOpen: boolean}>`
    ${(props) => display(props.isOpen ? "block" : "none")};
    ${backgroundColor("#fff")};
    ${p(2)};
    ${w100};
    height: calc(100vh - ${calculateRemSize(8)});
    overflow: scroll;
    overscroll-behavior: contain;
`;

const linksWrapper = css`
    ${flex("normal", "space-between")};
    ${flexDirection("column")};
    ${h100};
    ${pb(7)};
`;
