import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const LikeIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.333 2.33v1.337H9V4.2H5.8V2.055a.533.533 0 0 0-.534-.533v1.066l-.038.088-.184.424L4.222 5h-.555v2.933h4.8V7.4h-1.29v-.533h1.29v-.534h-1.29V5.8h1.29v-.533h-1.29v-.534h1.29V4.2H9v4.267H1V4.2h2.673v.267h.2l.859-1.989V1.163c.16-.101.345-.157.534-.163H5.3a1.064 1.064 0 0 1 1.033 1.33Zm-4.8 5.603h1.6v-3.2h-1.6v3.2Z"
            />
        </SvgIcon>
    );
};
