import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const HeartIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M6.867 2a2.128 2.128 0 0 0-1.51.625L5 2.983l-.358-.358a2.133 2.133 0 0 0-3.017 3.017l3 3L5 9.017l.377-.377 3-3A2.133 2.133 0 0 0 6.867 2ZM8 5.265l-1 1-2 2-2-2-1-1A1.602 1.602 0 1 1 4.265 3l.55.551.185.186.185-.184.192-.193.358-.36A1.602 1.602 0 1 1 8 5.265Z" />
        </SvgIcon>
    );
};
