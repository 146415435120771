import React from "react";
import classNames from "classnames";

import {svgIconWrapper} from "./SvgIconWrapper.module.css";

export type WrapperType = "circle" | "square";

interface IProps {
    children: React.ReactNode;
    wrapperColor: string;
    wrapperSize: string;
    wrapperType?: WrapperType;
    className?: string;
    onClick?: (event: React.MouseEvent) => void;
    as?: React.ElementType;
}

export const SvgIconWrapper: React.FC<IProps> = (props) => {
    const IconWrapper = props.as || "div";
    const wrapperType = props.wrapperType || "circle";
    const borderRadius = wrapperType === "circle" ? "50%" : "0%";
    const wrapperSize = {minWidth: `${props.wrapperSize}rem`, width: `${props.wrapperSize}rem`, height: `${props.wrapperSize}rem`};
    const wrapperColor = {backgroundColor: props.wrapperColor};
    const wrapperStyle = {borderRadius, ...wrapperSize, ...wrapperColor};
    const svgIconWrapperCN = classNames(svgIconWrapper, props.className);

    return (
        <IconWrapper style={wrapperStyle} className={svgIconWrapperCN} onClick={props.onClick}>
            {props.children}
        </IconWrapper>
    );
};
