import React from "react";
import classNames from "classnames";

import {disabled, inputStyle, multiline} from "../input/InputCore.module.css";

export type ITextareaCoreProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
    testid?: string;
};

export const TextareaCore = React.forwardRef<HTMLTextAreaElement, ITextareaCoreProps>((props, ref) => {
    const inputClassNames = classNames(inputStyle, props.disabled && disabled, multiline, props.className);

    const textareaRef = ref as React.ForwardedRef<HTMLTextAreaElement>;
    return <textarea ref={textareaRef} className={inputClassNames} {...props} disabled={props.disabled} data-testid={props.testid} />;
});
